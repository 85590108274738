#area-charts {
  display: block;
}

#area-charts h2 {
  text-align: center;
}

#area-charts .area-box-charts {
  display: flex;
}