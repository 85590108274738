.comments {
  display: block;
}

.comments h3 {
  text-align: center;
}

.comments .comment {
  font-style: italic;
  border-left: solid 1px #eee;
  border-bottom: solid 1px #eee;
  padding: 16px;
  margin: 8px;
}