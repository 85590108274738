html,
body,
#root {
  background: #008db7;
}


#head header {
  background: #008fb8 !important;
  color: #4e4f51;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
}

#head header .content-area {
  /* max-width: 1280px; */
  width: 92%;
  margin: 0 auto;
}

#head header .content-area>div {
  display: flex;
}

#head header *,
#head * {
  color: #fff !important;
  font-weight: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #4e4f51 !important;
}

#head .header-menu-navigation {
  margin: 0 48px;
  display: inline-block;
}

#head .logo {
  height: 60px;
  box-sizing: border-box;
  display: inline-block;
  padding: 6px;
}

#head .logo-image {
  max-height: 48px;
  box-sizing: border-box;
}

#head .options {
  right: 24px;
  position: absolute;
}

#menu-appbar>div {
  top: 56px !important;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12) !important;
}

#head .search {
  border-radius: 0px;
  border: solid 1px #ccc;
  width: 40%;
}

#head .area-serch-icon {
  display: inline;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

#head .content {
  max-width: 92%;
  text-align: left;
  width: 1460px;
  margin: 0 auto !important;
  left: -100px;
  position: relative;
}

#head .translate {
  position: absolute;
  right: 16px;
}

#head .translate button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: .8em;
}

#head .translate button:hover {
  color: #008db7;
}

#head .link-page {
  text-decoration: none;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;
  outline: none;
}

#head .evaluation {
  position: absolute;
  right: 16px;
}

#head .button-evaluate {
  background: #008db7;
  padding: 8px 24px;
  font-weight: bold;
  border-radius: 0;
  margin: 4px 8px;
}

#head .button-evaluate.forms {
  background: #666699;
}

#head .button-evaluate * {
  color: #fff !important;
}

@media (max-width: 720px) {
  #head header {
    height: 130px;
  }

  #head header .content-area>div {
    display: block;
    text-align: center;
  }

  #head .header-menu-navigation {
    margin: 0;
    display: block;
    padding: 16px 4px;
  }
}

@media (max-width: 468px) {
  #head header {
    height: 190px;
  }

  #head .link-page {
    text-decoration: none;
    margin: 0;
    text-transform: uppercase;
    font-size: .8em;
    cursor: pointer;
    outline: none;
    display: block;
    padding: 14px;
  }
}