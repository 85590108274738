body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fdfdfd;
}

#root {
  background-image: url(/images/bg_transparent_1.png);
  background-size: 100%;
  background-position: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body * {
  font-size: 1.02em;
}

.test {
  color: #666699;
  color: #6dbb9c;
  color: #008db7; 
  color: #e73b94;
  color: #cc3333;
  color: #ffcc66;
  color: #7c6491;
  color: #fe863d;
  color: #c8c94d;
  color: #a2766e;
  color: #737a84; 
}

