.list-pagination {
  display: block;
  margin-top: 24px;
  margin-bottom: 36px;
}
.list-pagination .item-pagination {
  padding: 8px;
  cursor: pointer;
  display: inline-block;
  border: solid 1px #eee;
  height: 40px;
  width: 40px;
  text-align: center;
  box-sizing: border-box;
  margin: 5px;
}

.list-pagination .item-pagination.current { 
  font-weight: bold;
}

.list-pagination .item-pagination.arrow {
  color: #ccc;
  position: relative;
  top: 8px;
}

.list-pagination .item-pagination.arrow.w2v *{
  color: #666699;
}
.list-pagination .item-pagination.arrow.concordance *{
  color: #e73b94;
}
.list-pagination .item-pagination.arrow.direct *{
  color: #008db7;
}
.list-pagination .item-pagination.arrow.all *{
  color: #008db7;
}

.list-pagination .item-pagination.current.w2v {
  border-color: #666699;
}
.list-pagination .item-pagination.current.concordance {
  border-color: #e73b94;
}
.list-pagination .item-pagination.current.w2v {
  border-color: #008db7;
}
.list-pagination .item-pagination.current.all {
  border-color: #008db7;
}
.list-pagination .item-pagination.current.direct {
  border-color: #008db7;
}