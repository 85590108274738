#area-bar-code-chart-component .axis path,
#area-bar-code-chart-component .axis line {
  fill: none;
  stroke: #4e4f51;
  shape-rendering: crispEdges;
}

#area-bar-code-chart-component .dot {}

#area-bar-code-chart-component .dot-stroke {
  stroke: #111;
  stroke-width: 1px;
  fill: none
}

#area-bar-code-chart-component .tooltip {
  position: absolute;
  width: 200px;
  height: 28px;
  pointer-events: none;
}

#area-bar-code-chart-component .tick text {
  font-size: 10px;
  /* fill: #999 */
  opacity: 0;
}

#area-bar-code-chart-component .tick line {
  opacity: 0;
}

#area-bar-code-chart-component .axis path,
#area-bar-code-chart-component .axis line {
  fill: none;
  stroke: #4e4f51;
  opacity: 0;
}

#area-bar-code-chart-component text {
  font-family: 'Ubuntu', sans-serif;
}

#area-bar-code-chart-component text.txt {
  font-size: 12px;
  color: #4e4f51;
}