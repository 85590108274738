#search {
  display: block;
  margin: 0;
  padding: 0;
  /* background-image: url(/images/bg_6.jpg); */
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: -moz-calc(100% - 118px);
	min-height: -webkit-calc(100% - 118px);
	min-height: -o-calc(100% - 118px);
  min-height: calc(100% - 118px);
  box-sizing: border-box;
}

#search .content{
  max-width: 1460px;
  margin: 0 auto;
  display: block;
}

#search .be-subtitle-page {
  font-family: "Ubuntu", sans-serif !important;
	font-size: 24px;
	border-bottom: solid 1px #737a84;
	padding: 20px 0;
	width: 80%;
}

#search iframe, #search .area-iframe{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background: #eee;
  box-sizing: border-box;
}

#search .be-title-page span{
  color: #008db7;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif !important;
}

#search #results {
  margin-top: 48px;
}

#search .link-term {
  color: #666699 !important;
}

#search .dictionary-title{
  color: #008db7;
}

#search .card-be {
  border-radius: 0;
  box-shadow: none;
  border: solid 1px #eee;
  border-bottom: solid 4px #EEE;
}

#search .card-dictionary {
  padding: 0 16px;
}

#search .box-verse {
  background: #fff;
  border: solid 1px #eee;
  display: block;
  padding: 4px 16px;
  cursor: pointer;
  margin: 8px 0;
  border-bottom: solid 2px #eee;
}

#search .verse-text {
  margin-right: 8px;
}

#search .card-index {
  padding: 0;
  margin: 10px auto;
  margin-top: 30px;
}

#search .box-verse .area-about {
  display: none
}

#search .box-verse:hover .area-about {
  display: block
}

#search .box-feedback {
  display: table;
}

#search .box-feedback p {
  display: inline-table;
  vertical-align: middle;
}

#search .box-feedback .box-icons-like {
  display: inline-table;
  vertical-align: middle;
  margin-left: 0px;
}

#search .box-feedback .box-icons-like .like-icon {
  margin: 0px 4px;
}

#search .box-feedback .box-icons-like .like-icon svg {
  font-size: 16px;
  fill: #ccc;
}

#search .box-feedback .box-icons-like .like-icon.up.selected svg {
  fill: #6dbb9c
}
#search .box-feedback .box-icons-like .like-icon.down.selected svg {
  fill: #cc3333;
}

#search .card-index .area-index {
  padding: 0;
}

#search .card-index .area-index iframe{
  height: 480px;
}

#search .loading {
  margin: 50px auto;
  display: block;
  text-align: center
}

#search .loading-be,
#search .empty-state {
  display: block;
  margin: 0 auto;
  text-align: center;  
  padding-top: 200px;
}

#search .loading-be p {
  font-family: 'Ubuntu', sans-serif !important;
}

#search .loading-be img {
  max-width: 400px;
}

#search .empty-state img {
  max-width: 160px;
}

#search .select-sort {
  display: block;
}

#search .select-sort span {
  opacity: .6;
  cursor: pointer;
  margin: auto 8px;
}

#search .select-sort span.selected{
  opacity: 1;
  font-weight: bold;
  color: #000;
}

#search .tool-bar .slider {
  width: 100px;
  height: 2px;
  background: #008db7;
}

#search .disable-button-explicability {
  cursor: none;
}

#search .disable-button-explicability * {
  color: #ccc;
}

#search .tool-bar .link-page.link-featured.disable-button-explicability .icon-tab-item.help-tab * {
  color: #eee;
}

@media (max-width: 1040px)  {
  #search .content.results{
    padding: 0px 8px !important;
    width: 92% !important;
    max-width: 92% !important;
  }
}
