#evaluate-header header {
  /* background: #4e4f51; */
  background: #fff !important;
  color: #4e4f51;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
  text-align: center;
}

#evaluate-header header > div {
  text-align: center;
  width: auto;
  margin: 0 auto;
}

#evaluate-header header *, #evaluate-header * {
  color: #4e4f51 !important;
  font-weight: normal;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4e4f51 !important;
}
#search-home .slogan-project {
	margin: 40px auto;
}

#evaluate-header .options {
  right: 24px;
  position: absolute;
}
#menu-appbar > div {
  top: 56px !important;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12) !important;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12) !important;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12) !important;
}

#evaluate-header .logo {
  max-height: 48px;
  margin-right: 16px;
  box-sizing: border-box;
}

#evaluate-header .search {
  border-radius: 0px;
  border: solid 1px #ccc;
  width: 40%;
}

#evaluate-header .area-serch-icon {
  display: inline;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

#evaluate-header .content {
  max-width: 80%;
  text-align: left;
  width: 1460px;
  margin: 0 auto !important;
  left: -100px;
  position: relative;
}
#evaluate-header .translate {
  position: absolute;
  right: 16px;
}

#evaluate-header .translate button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: .8em;
}

#evaluate-header .translate button:hover{
  /* font-size: .85em; */
  color: #008db7;
}

#evaluate-header .link-page {
  text-decoration: none;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 24px;
}

#evaluate-header .link-page .icon-button {
  font-size: 16px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}

@media (max-width: 1720px)  {
  #evaluate-header .content {
    max-width: 80%;
    text-align: left;
    width: 80%;
    margin: 0 !important;
    top: -82px;
    position: relative;
  }

  #evaluate-header .search {
    position: absolute;
    top: 65px;
    width: 84%;
    display: block;
    margin: 0 auto;
    left: 120px;
  }
}

@media (max-width: 550px)  {
  #evaluate-header .link-page {
    display: none;
  }
}
