#search .link-verse-bible {
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
  color: #808080;
}

#search .link-verse-bible:hover {
  color: #008db7;
}
