#footer {
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 54px;
  background: rgba(255, 255, 255, .9); 
  padding: 4px 0;
  padding-top: 0;
  box-sizing: border-box;
  margin-top: 0
}

#footer .copyright {
  /* border-top: solid 1px rgba(255, 255, 255, .2); */
  padding-bottom: 8px;
  background: #008db7;
  padding-top: 4px;
}

#footer .copyright * {
  color: #fff !important;
  font-weight: bold;
}