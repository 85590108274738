#bible {
  min-height: -moz-calc(100% - 118px);
  min-height: -webkit-calc(100% - 118px);
  min-height: -o-calc(100% - 118px);
  min-height: calc(100% - 118px);
  height: auto;
  box-sizing: border-box;
  background-color: #fff;
  padding-bottom: 48px;
}

#bible .id-verse {
  color: #008db7;
  margin: 20px;
}

#bible .be-title-page {
  color: #006699;
}

#bible .be-title-page span {
  color: #3399cc;
}

#bible .tool-bar {
  background-color: #666699;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 80px;
}

#bible .tool-bar * {
  color: #fff;
}

#bible .tool-bar option {
  color: #333;
}

#bible .content-form label {
  margin-top: -8px;
}

#bible .tool-bar *::after,
#bible .tool-bar *::before {
  border-bottom: 2px solid rgba(255, 255, 255, .5) !important;
  border-top: 2px solid rgba(255, 255, 255, .5) !important;
  border-left: 2px solid rgba(255, 255, 255, .5) !important;
  border-right: 2px solid rgba(255, 255, 255, .5) !important;
  height: 32px;
  margin-right: -4px;
  margin-left: -8px;
}

#bible .selected-verse {
  border: solid 1px #ccc;
  margin: 0px -16px;
  padding: 4px 16px;
}

#bible .loading-be {
  margin: 0 auto;
  display: block;
  text-align: center;
  padding: 10%;
  box-sizing: border-box;
}

#bible .loading-be img {
  max-width: 30%;
}

#bible .tool-bar .content-form {
  text-align: left;
  margin: 0 auto;
  max-width: 90%;
  width: 90%;
}

#bible .read-content {
  max-width: 90%;
  margin: 0 auto;
}

@media (max-width: 405px) {
  #bible .tool-bar {
    height: 180px;
  }
}