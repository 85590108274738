#logs {
  display: block;
  margin: 0;
}

#logs .logo-logs {
  text-align: center;
  margin: 0 auto;
  padding-top: 48px;
  display: block;
  margin-bottom: 48px;
  -webkit-box-shadow: 0px 0px 23px -2px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 23px -2px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 23px -2px rgba(0,0,0,0.15);
}

#logs .logo-logs .logo {
  max-width: 140px;
}

#logs .box-logs-dashboard {
  max-width: 1080px;
  margin: 0 auto;
}

#logs .row-log  {
  border-top: solid 1px #eee;
  padding: 8px 4px; 
}

#logs .row-log .destaque {
  font-weight: bold;
}

#logs .box-logs-last-searches {
  max-width: 800px;
  display: block;
  margin: 0 auto
}

.header-logs-biblexplorer {
  display: flex;
  justify-content: center;
}

.header-logs-biblexplorer .item-header-logs {
  margin: 12px;
  padding: 8px;
  border-bottom: solid 1px transparent;
}

.header-logs-biblexplorer .item-header-logs:hover {
  margin: 12px;
  padding: 8px;
  color: #e73b94;
}