#about {
  background-color: #fafafa;
}

#about .slider {
  padding-top: 60px;
}

#about .slider .content {
  background-image: url(/images/biblexplorer_art_site.png) !important;
  background-size: 300px auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 450px;
}

#about .slider .content label {
  font-size: .7em;
  text-transform: uppercase;
  text-align: left;
}

#about .slider .content a {
  text-decoration: none;
  border-color: #4e4f51;
}

#about .slider .content p {
  max-width: 650px;
}

#about .slider .content h2 {
  margin-top: 0px;
}

#about .slider .content h3,
.slider .content h4 {
  color: #000;
}

#about .slider .content .vers {
  font-style: italic;
}

#about .slider .content .be-title-page {
  border-bottom: solid 1px #4e4f51;
}

#about .slider .content * {
  color: #4e4f51 !important;
}

#about .content.last {
  padding-bottom: 60px;
}

#about .slider .content p,
.slider .content h2,
.slider .content h3 {
  color: #000
}

#about .about .content {
  text-align: center;
}

#about .about h2 {
  padding-bottom: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 250px;
}

a {
  text-decoration: none;
  color: #4e4f51 !important
}

.area-person {
  display: inline-flex;
  padding: 0 30px;
}

.area-person img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 30px;
}

.area-person .area-info {
  display: inline-block;
  width: 90%;
}

.area-person .area-info .name {
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
}

.area-person .area-info .description {
  font-style: italic;
}

@media (max-width: 1210px) {
  #about .slider .content {
    background-size: 300px auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 950px;
  }

  #about .content {
    padding: 0px 60px;
  }
}