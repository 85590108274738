#list-books .box-index-bible {
  display: block;
  width: 90%;
  margin: 0 auto; 
}

#list-books .box-index-bible.box-index-size-3,
#list-books .box-index-bible.box-index-size-4 {
  width: 100%;
}

#list-books .box-testament {
  display: inline-block;
  vertical-align: text-top;
  width: 20%;
  text-align: left;
  max-height: 400px;
  margin: 10px;
  background: rgba(255, 255, 255, .7);
  /* -webkit-box-shadow: -4px 5px 15px -9px rgba(0,0,0,0.18);  
  -moz-box-shadow: -4px 5px 15px -9px rgba(0,0,0,0.18);
  box-shadow: -4px 5px 15px -9px rgba(0,0,0,0.18); */
}

#list-books .box-testament.box-size-3 {
  width: 40%;
}

#list-books .box-testament.box-size-4 {
  width: 48%;
  margin: auto 1%;
}

#list-books .title-testament {
  text-transform: uppercase;
  color: #008db7;
  border-bottom: solid 1px  #008db7;
  padding-bottom: 11px;
  font-size: 14px;
}

#list-books .books-bible {
  display: block;
}

#list-books .books-bible p {
  margin: 3px;
  font-size: 13px;
  cursor: pointer;
} 

#list-books .columns-2 {
  -webkit-column-count:2;
  -moz-column-count:2;
  column-count:2;
}

#list-books .columns-3 {
  -webkit-column-count:3;
  -moz-column-count:3;
  column-count:3;
}

#list-books .columns-4 {
  -webkit-column-count:4;
  -moz-column-count:4;
  column-count:4;
}

#list-books .link-bible{
  text-decoration: none;
}