@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Poppins|Ubuntu);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Poppins|Ubuntu);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fdfdfd;
}

#root {
  background-image: url(/images/bg_transparent_1.png);
  background-size: 100%;
  background-position: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body * {
  font-size: 1.02em;
}

.test {
  color: #666699;
  color: #6dbb9c;
  color: #008db7; 
  color: #e73b94;
  color: #cc3333;
  color: #ffcc66;
  color: #7c6491;
  color: #fe863d;
  color: #c8c94d;
  color: #a2766e;
  color: #737a84; 
}


html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fdfdfd;
}

body * {
  font-family: 'Lato', 'Roboto', sans-serif !important;
  /* font-weight: 300 */
  color: #4e4f51;
}

.page {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: -o-calc(100% - 168px);
  height: calc(100% - 168px);
  box-sizing: border-box;
}

.page iframe {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
}


h1,
h2,
h3,
h4,
h5 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  color: #4e4f51 !important;
}

p,
span {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
}

.content {
  max-width: 1460px;
  margin: 0 auto;
  display: block
}

.be-title {
  font-family: "Ubuntu", sans-serif;
  font-size: 30px;
}

.be-title-page {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 24px;
  border-bottom: solid 1px #737a84;
  padding: 20px 0;
  width: 60%;
}

html,
body,
#app,
#app>div {
  height: 100%
}

.be-card {
  border-radius: 0 !important;
}

.be-color-purple {
  color: #666699;
}

.be-color-blue {
  color: #008db7;
}

.be-color-green {
  color: #6dbb9c;
}

.be-color-yellow {
  color: #ffcc66;
}

.be-color-pink {
  color: #e73b94;
}

.be-color-red {
  color: #cc3333;
}

.be-featured {
  font-weight: bold;
}

@media (max-width: 1920px) {
  .content {
    max-width: 80% !important;
    margin: 0 auto;
    display: block
  }
}
html,
body,
#root {
  background: #008db7;
}


#head header {
  background: #008fb8 !important;
  color: #4e4f51;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
}

#head header .content-area {
  /* max-width: 1280px; */
  width: 92%;
  margin: 0 auto;
}

#head header .content-area>div {
  display: flex;
}

#head header *,
#head * {
  color: #fff !important;
  font-weight: normal;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #4e4f51 !important;
}

#head .header-menu-navigation {
  margin: 0 48px;
  display: inline-block;
}

#head .logo {
  height: 60px;
  box-sizing: border-box;
  display: inline-block;
  padding: 6px;
}

#head .logo-image {
  max-height: 48px;
  box-sizing: border-box;
}

#head .options {
  right: 24px;
  position: absolute;
}

#menu-appbar>div {
  top: 56px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12) !important;
}

#head .search {
  border-radius: 0px;
  border: solid 1px #ccc;
  width: 40%;
}

#head .area-serch-icon {
  display: inline;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

#head .content {
  max-width: 92%;
  text-align: left;
  width: 1460px;
  margin: 0 auto !important;
  left: -100px;
  position: relative;
}

#head .translate {
  position: absolute;
  right: 16px;
}

#head .translate button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: .8em;
}

#head .translate button:hover {
  color: #008db7;
}

#head .link-page {
  text-decoration: none;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;
  outline: none;
}

#head .evaluation {
  position: absolute;
  right: 16px;
}

#head .button-evaluate {
  background: #008db7;
  padding: 8px 24px;
  font-weight: bold;
  border-radius: 0;
  margin: 4px 8px;
}

#head .button-evaluate.forms {
  background: #666699;
}

#head .button-evaluate * {
  color: #fff !important;
}

@media (max-width: 720px) {
  #head header {
    height: 130px;
  }

  #head header .content-area>div {
    display: block;
    text-align: center;
  }

  #head .header-menu-navigation {
    margin: 0;
    display: block;
    padding: 16px 4px;
  }
}

@media (max-width: 468px) {
  #head header {
    height: 190px;
  }

  #head .link-page {
    text-decoration: none;
    margin: 0;
    text-transform: uppercase;
    font-size: .8em;
    cursor: pointer;
    outline: none;
    display: block;
    padding: 14px;
  }
}
.social-networks{
	text-align: center;
	display: block;
	flex: 1 1;
	width: 100%;
	background: #008db7;
	margin: 0 !important;
}
.area-footer, .box-icons{
	margin: 0 !important;
	padding: 0 !important;
}
.area-footer .box-icons, .box-icons,
.footer-home .box-icons {
	height: 80px;
}
.area-footer .icon, .box-icons .icon,
.footer-home .icon {
	background-image: url(/images/icons_social_networks_white.png);
	background-color: transparent;
	background-size: 300px 100px;
	background-position: 300px 0;
	height: 50px;
	width: 50px;
	cursor: pointer;
	display: inline-block;
	margin: 0px;
	margin-top: 10px;
	transition: all .2s linear;
	box-sizing: border-box;
	border-radius: 25px;
}
.area-footer .icon:hover, .box-icons .icon:hover,
.footer-home .icon:hover {
	margin-bottom: 10px;
	margin-top: 0;
	background-image: url(/images/icons_social_networks_white.png);
}
.area-footer #icon-01,
.box-icons #icon-01 {
	background-position: 0 0;
}
.area-footer #icon-02,
.box-icons #icon-02 {
	background-position: -50px 0;
}
.area-footer #icon-03,
.box-icons #icon-03 {
	background-position: -100px 0;
}
.area-footer #icon-04,
.box-icons #icon-04 {
	background-position: -150px 0;
}
.area-footer #icon-05,
.box-icons #icon-05 {
	background-position: -200px 0;
}
.area-footer #icon-06,
.box-icons #icon-06 {
	background-position: 0 50px;
}
.area-footer #icon-07,
.box-icons #icon-07 {
	background-position: -50px 50px;
}
.area-footer #icon-08,
.box-icons #icon-08 {
	background-position: -100px 50px;
}
.area-footer #icon-09,
.box-icons #icon-09 {
	background-position: -150px 50px;
}
.area-footer #icon-10,
.box-icons #icon-10 {
	background-position: -200px 50px;
}
.area-footer #icon-11,
.box-icons #icon-11 {
	background-position: -250px 0px;
}

@media only screen and (max-width: 970px){
	.column-footer{
		display: block;
		width: 100% !important;
		max-width: 100% !important;
		text-align: center;
		margin-top: 10px;
		padding-bottom: 10px;
		border-bottom: solid 1px #deeeee;
	}
}

#footer {
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 54px;
  background: rgba(255, 255, 255, .9); 
  padding: 4px 0;
  padding-top: 0;
  box-sizing: border-box;
  margin-top: 0
}

#footer .copyright {
  /* border-top: solid 1px rgba(255, 255, 255, .2); */
  padding-bottom: 8px;
  background: #008db7;
  padding-top: 4px;
}

#footer .copyright * {
  color: #fff !important;
  font-weight: bold;
}
#home {
  display: block;
  margin: 0;
  padding: 0;
  background-color: #008db7;
}

#home .content * {
  color: #fff
}

#home .content {
  max-width: 1280px;
  text-align: center;
  padding-top: 64px;
}

#home .content .banner {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

#home .content .slogan-project {
  font-size: 32px;
  font-weight: bold;
}

@media (max-width: 1280px) {
  #home {
    max-width: 100%;
  }

  #home .content {
    min-height: 560px;
    padding-top: 48px;
  }
}

@media (max-width: 940px) {
  #home .content {
    max-width: 90% !important;
    min-height: 420px;
    padding-top: 36px;
  }

  #home .content .banner {
    width: 100%;
  }
}

@media (max-width: 720px) {
  #home .content {
    max-width: 100% !important;
    min-height: 560px;
  }
}
#about {
  background-color: #fafafa;
}

#about .slider {
  padding-top: 60px;
}

#about .slider .content {
  background-image: url(/images/biblexplorer_art_site.png) !important;
  background-size: 300px auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 450px;
}

#about .slider .content label {
  font-size: .7em;
  text-transform: uppercase;
  text-align: left;
}

#about .slider .content a {
  text-decoration: none;
  border-color: #4e4f51;
}

#about .slider .content p {
  max-width: 650px;
}

#about .slider .content h2 {
  margin-top: 0px;
}

#about .slider .content h3,
.slider .content h4 {
  color: #000;
}

#about .slider .content .vers {
  font-style: italic;
}

#about .slider .content .be-title-page {
  border-bottom: solid 1px #4e4f51;
}

#about .slider .content * {
  color: #4e4f51 !important;
}

#about .content.last {
  padding-bottom: 60px;
}

#about .slider .content p,
.slider .content h2,
.slider .content h3 {
  color: #000
}

#about .about .content {
  text-align: center;
}

#about .about h2 {
  padding-bottom: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 250px;
}

a {
  text-decoration: none;
  color: #4e4f51 !important
}

.area-person {
  display: inline-flex;
  padding: 0 30px;
}

.area-person img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 30px;
}

.area-person .area-info {
  display: inline-block;
  width: 90%;
}

.area-person .area-info .name {
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
}

.area-person .area-info .description {
  font-style: italic;
}

@media (max-width: 1210px) {
  #about .slider .content {
    background-size: 300px auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 950px;
  }

  #about .content {
    padding: 0px 60px;
  }
}
#bible {
  min-height: -o-calc(100% - 118px);
  min-height: calc(100% - 118px);
  height: auto;
  box-sizing: border-box;
  background-color: #fff;
  padding-bottom: 48px;
}

#bible .id-verse {
  color: #008db7;
  margin: 20px;
}

#bible .be-title-page {
  color: #006699;
}

#bible .be-title-page span {
  color: #3399cc;
}

#bible .tool-bar {
  background-color: #666699;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 80px;
}

#bible .tool-bar * {
  color: #fff;
}

#bible .tool-bar option {
  color: #333;
}

#bible .content-form label {
  margin-top: -8px;
}

#bible .tool-bar *::after,
#bible .tool-bar *::before {
  border-bottom: 2px solid rgba(255, 255, 255, .5) !important;
  border-top: 2px solid rgba(255, 255, 255, .5) !important;
  border-left: 2px solid rgba(255, 255, 255, .5) !important;
  border-right: 2px solid rgba(255, 255, 255, .5) !important;
  height: 32px;
  margin-right: -4px;
  margin-left: -8px;
}

#bible .selected-verse {
  border: solid 1px #ccc;
  margin: 0px -16px;
  padding: 4px 16px;
}

#bible .loading-be {
  margin: 0 auto;
  display: block;
  text-align: center;
  padding: 10%;
  box-sizing: border-box;
}

#bible .loading-be img {
  max-width: 30%;
}

#bible .tool-bar .content-form {
  text-align: left;
  margin: 0 auto;
  max-width: 90%;
  width: 90%;
}

#bible .read-content {
  max-width: 90%;
  margin: 0 auto;
}

@media (max-width: 405px) {
  #bible .tool-bar {
    height: 180px;
  }
}
#gallery {
  background: #fff;
  background-size: 150% auto;
  background-position: center;
  padding-top: 20px;
  box-sizing: border-box;
  padding-bottom: 50px;
}

#gallery .card-gallery-item {
  display: inline-block;
  margin: 10px;
  width: 280px;
  margin-bottom: 64px;
  border: solid 1px #f3f3f3;
  border-bottom: solid 4px #eee;
  cursor: pointer;
}

#gallery .area-media {
  height: 160px;
}

#gallery .area-content {
  height: 80px;
}

#gallery h1, 
#gallery h2, 
#gallery h3, 
#gallery h4 {
  font-family: 'Lato', sans-serif !important;
  font-weight: 300 !important;
}

#gallery .card-gallery-item h1, 
#gallery .card-gallery-item h2, 
#gallery .card-gallery-item h3, 
#gallery .card-gallery-item h4 {
  color: #006699;
}

#gallery .card-gallery-item .MuiPaper-elevation1-20 {
  box-shadow: none !important;
}

#visualiation-page {
  /* height: 100%; */
}

#visualiation-page .tool-bar {
  /* background-color: #6dbb9c;
  background-color: #008db7;
  background-color: #e73b94;
  background-color: #ffcc66;
  background-color: #cc3333;
  background-color: #7c6491;
  background-color: #fe863d;
  background-color: #c8c94d;
  background-color: #a2766e;
  background-color: #737a84; 
  background-color: #666699;*/
  background-color: #008db7;
}

#visualiation-page .tool-bar * {
  color: #fff !important;
}

#visualiation-page .tool-bar .message {
  font-size: .85em;
}

#visualiation-page .tool-bar .link-page {
  text-decoration: none;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: .8em;
  color: #fff;
}
#search-tool-bar {
  display: block;
}

#search .tool-bar {
  box-shadow: none;
}

#search .tool-bar .link-page.link-featured {
  display: inline;
}

#search .tool-bar .link-page.link-featured .icon-tab-item {
  display: inline-block;
  position: relative;
  top: 8px;
  margin-right: 4px;
}

#search .tool-bar .link-page.link-featured.all.selected {
  border-bottom: solid 2px #333
}

#search .tool-bar .link-page.link-featured .icon-tab-item.direct-tab * {
  color: #008db7;
}

#search .tool-bar .link-page.link-featured.direct.selected {
  border-bottom: solid 2px #008db7
}

#search .tool-bar .link-page.link-featured .icon-tab-item.concordance-tab * {
  color: #e73b94;
}

#search .tool-bar .link-page.link-featured.concordance.selected {
  border-bottom: solid 2px #e73b94
}

#search .tool-bar .link-page.link-featured .icon-tab-item.terms-tab * {
  color: #666699;
}

#search .tool-bar .link-page.link-featured.w2v.selected {
  border-bottom: solid 2px #666699
}

#search .tool-bar .link-page.link-featured .icon-tab-item.help-tab * {
  color: #6dbb9c;
}

#search .tool-bar .link-page.link-featured .icon-tab-item.model-tab * {
  color: #cc3333;
}

#search .tool-bar .link-page.link-featured.explicability-search.selected {
  border-bottom: solid 2px #6dbb9c
}

#search .tool-bar .link-page.link-featured.explicability-model.selected {
  border-bottom: solid 2px #cc3333
}

#search .tool-bar {
  background: #fefefe;
  border-bottom: solid #ccc 1px;
  box-shadow: none;
  padding-top: 20px;
  box-sizing: border-box;
  height: 64px;
}

#search .tool-bar.green-tool-bar {
  background: #6dbb9c;
  box-shadow: none;
}

#search .tool-bar * {
  /* color: #fff !important; */
}

#search .tool-bar .link-page {
  text-decoration: none;
  margin: 0;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;

  background: transparent;
  -webkit-transition-property: background;
  -webkit-transition-duration: 1s;
  transition-property: background;
  transition-duration: 1s;
}

#search .tool-bar .link-page:hover {
  background: #f4f4f4;
}

#search .tool-bar .link-page.link-featured {
  border: solid 1px rgba(255, 255, 255, .4);
  padding: 15px 16px;
}


@media (max-width: 1718px)  {
  #search .tool-bar .content{
    max-width: 95% !important;
  }
}

@media (max-width: 1460px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search .tool-bar .link-page.link-featured {
    padding: 16px 8px;
  }
}

@media (max-width: 1362px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search .tool-bar .link-page.link-featured {
    padding: 16px 4px;
  }
  #search .tool-bar .link-page.link-featured span {
    font-size: 10px;
  }
}

@media (max-width: 850px) {
  #search #search-tool-bar .tool-bar .link-page.link-featured {
    display: block !important;
    padding: 8px;
    background: #fafafa;
    position: relative;
    top: 10px;
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured .icon-tab-item {
    top: 1px;
    margin-right: 8px;
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured span {
    font-size: 12px;
    position: relative;
    top: -8px;
  }

  #search #search-tool-bar .tool-bar {
    height: 248px;
    border-top: solid 1px #ccc;
    padding: 0;
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured.all.selected {
    border: solid 2px #333
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured.direct.selected {
    border: solid 2px #008db7
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.concordance.selected {
    border: solid 2px #e73b94
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.w2v.selected {
    border: solid 2px #666699
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.explicability-search.selected {
    border: solid 2px #6dbb9c
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.explicability-model.selected {
    border: solid 2px #cc3333
  }
}

@media (max-width: 800px)  {
  #search .tool-bar .no-responsive {
     display: none;
  }
}
#search {
  display: block;
  margin: 0;
  padding: 0;
  /* background-image: url(/images/bg_6.jpg); */
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
	min-height: -o-calc(100% - 118px);
  min-height: calc(100% - 118px);
  box-sizing: border-box;
}

#search .content{
  max-width: 1460px;
  margin: 0 auto;
  display: block;
}

#search .be-subtitle-page {
  font-family: "Ubuntu", sans-serif !important;
	font-size: 24px;
	border-bottom: solid 1px #737a84;
	padding: 20px 0;
	width: 80%;
}

#search iframe, #search .area-iframe{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background: #eee;
  box-sizing: border-box;
}

#search .be-title-page span{
  color: #008db7;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif !important;
}

#search #results {
  margin-top: 48px;
}

#search .link-term {
  color: #666699 !important;
}

#search .dictionary-title{
  color: #008db7;
}

#search .card-be {
  border-radius: 0;
  box-shadow: none;
  border: solid 1px #eee;
  border-bottom: solid 4px #EEE;
}

#search .card-dictionary {
  padding: 0 16px;
}

#search .box-verse {
  background: #fff;
  border: solid 1px #eee;
  display: block;
  padding: 4px 16px;
  cursor: pointer;
  margin: 8px 0;
  border-bottom: solid 2px #eee;
}

#search .verse-text {
  margin-right: 8px;
}

#search .card-index {
  padding: 0;
  margin: 10px auto;
  margin-top: 30px;
}

#search .box-verse .area-about {
  display: none
}

#search .box-verse:hover .area-about {
  display: block
}

#search .box-feedback {
  display: table;
}

#search .box-feedback p {
  display: inline-table;
  vertical-align: middle;
}

#search .box-feedback .box-icons-like {
  display: inline-table;
  vertical-align: middle;
  margin-left: 0px;
}

#search .box-feedback .box-icons-like .like-icon {
  margin: 0px 4px;
}

#search .box-feedback .box-icons-like .like-icon svg {
  font-size: 16px;
  fill: #ccc;
}

#search .box-feedback .box-icons-like .like-icon.up.selected svg {
  fill: #6dbb9c
}
#search .box-feedback .box-icons-like .like-icon.down.selected svg {
  fill: #cc3333;
}

#search .card-index .area-index {
  padding: 0;
}

#search .card-index .area-index iframe{
  height: 480px;
}

#search .loading {
  margin: 50px auto;
  display: block;
  text-align: center
}

#search .loading-be,
#search .empty-state {
  display: block;
  margin: 0 auto;
  text-align: center;  
  padding-top: 200px;
}

#search .loading-be p {
  font-family: 'Ubuntu', sans-serif !important;
}

#search .loading-be img {
  max-width: 400px;
}

#search .empty-state img {
  max-width: 160px;
}

#search .select-sort {
  display: block;
}

#search .select-sort span {
  opacity: .6;
  cursor: pointer;
  margin: auto 8px;
}

#search .select-sort span.selected{
  opacity: 1;
  font-weight: bold;
  color: #000;
}

#search .tool-bar .slider {
  width: 100px;
  height: 2px;
  background: #008db7;
}

#search .disable-button-explicability {
  cursor: none;
}

#search .disable-button-explicability * {
  color: #ccc;
}

#search .tool-bar .link-page.link-featured.disable-button-explicability .icon-tab-item.help-tab * {
  color: #eee;
}

@media (max-width: 1040px)  {
  #search .content.results{
    padding: 0px 8px !important;
    width: 92% !important;
    max-width: 92% !important;
  }
}

#search .link-verse-bible {
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
  color: #808080;
}

#search .link-verse-bible:hover {
  color: #008db7;
}

#search .area-results h4 {
  margin-top: 64px;
}

#search .area-results h4.w2v .resource {
  color: #666699;
  font-weight: bold
}

#search .area-results h4.concordance .resource {
  color: #e73b94;
  font-weight: bold
}

#search .area-results h4.direct .resource {
  color: #008db7;
  font-weight: bold
}

#search .area-results .box-verse.concordance {
  border-left: solid 3px #e73b94;
}

#search .area-results .box-verse.w2v {
  border-left: solid 3px #666699;
}

#search .area-results .box-verse.direct {
  border-left: solid 3px  #008db7 
}
#search .verse-id .link-verse {
  font-style: italic;
  color: #008db7 !important;
  font-size: .85em;
}

#search .verse-id .link-verse:hover {
  text-decoration: underline !important;
}

#search .verse-about-box {
  font-size: .9em;
  color: #999;
  margin: 4px 16px;
  border: solid 1px #eee;
  padding: 8px;
  background: #f3f3f3;
}

#search .verse-about-box .verse-about-result .search-id {
  font-weight: bold;
}

#search .verse-about-box .verse-about-result.w2v .search-id {
  color: #666699;
}

#search .verse-about-box .verse-about-result.direct .search-id {
  color: #000;
}

#search .verse-about-box .verse-about-result.concordance .search-id {
  color: #e73b94;
}

#search .featured-term-search {
 font-weight: 700;
 color: #222; 
 /* text-decoration: underline; */
}

.list-pagination {
  display: block;
  margin-top: 24px;
  margin-bottom: 36px;
}
.list-pagination .item-pagination {
  padding: 8px;
  cursor: pointer;
  display: inline-block;
  border: solid 1px #eee;
  height: 40px;
  width: 40px;
  text-align: center;
  box-sizing: border-box;
  margin: 5px;
}

.list-pagination .item-pagination.current { 
  font-weight: bold;
}

.list-pagination .item-pagination.arrow {
  color: #ccc;
  position: relative;
  top: 8px;
}

.list-pagination .item-pagination.arrow.w2v *{
  color: #666699;
}
.list-pagination .item-pagination.arrow.concordance *{
  color: #e73b94;
}
.list-pagination .item-pagination.arrow.direct *{
  color: #008db7;
}
.list-pagination .item-pagination.arrow.all *{
  color: #008db7;
}

.list-pagination .item-pagination.current.w2v {
  border-color: #666699;
}
.list-pagination .item-pagination.current.concordance {
  border-color: #e73b94;
}
.list-pagination .item-pagination.current.w2v {
  border-color: #008db7;
}
.list-pagination .item-pagination.current.all {
  border-color: #008db7;
}
.list-pagination .item-pagination.current.direct {
  border-color: #008db7;
}
#explicability {
  
}

#explicability h2, #explicability h3 {
  width: 80%;
  margin-top: 42px;
}

#explicability .container-left {
  max-width: 59%;
}

#explicability .container-right.chart {
  margin-left: 72px;
  padding-top: 96px;
}

#explicability .container-right {
  max-width: 40%;
  height: 500px;
  display: inline-block;
  width: 40%;
}

#explicability .container-area,
#explicability .container-flex  {
  display: flex;
}

#explicability .container-block {
  display: block;
}

#explicability .area-1 .container-right {
  background-image: url(/images/explicabilidade-img-1.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .area-2 .container-right {
  background-image: url(/images/termos_relacionados.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .area-3 .container-right {
  background-image: url(/images/concordancia_biblica.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .area-4 .container-right.img-1 {
  background-image: url(/images/calculos.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .container-right.img-2 {
  background-image: url(/images/grafismo_metodo.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}


#explicability .box-1 .similar-terms-text {
  max-width: 59%;
}

#explicability .color-text,
#explicability .featured {
  color: #6dbb9c !important;
}

#explicability .featured-2 {
  color: #008db7;
}

#explicability .box-count-results {
  padding: 16px;
  border: solid 1px;
  width: 200px;
  font-family: 'Ubuntu', sans-serif !important;
}
#explicability .box-count-results .color {
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 1.5em;
}

#explicability .box-count-results.pink {
  border-color: #e73b94;
}

#explicability .box-count-results.pink .color, 
#explicability .text-pink-color{
  color: #e73b94 !important;
}

#explicability .box-count-results.purple {
  border-color: #666699;
}

#explicability .box-count-results.purple .color{
  color: #666699;
}

#explicability .box-count-results.green {
  border-color: #6dbb9c;
}

#explicability .box-count-results.green .color{
  color: #6dbb9c;
}

#explicability .box-count-results.blue {
  border-color: #6dbb9c;
}

#explicability .box-count-results.blue .color{
  color: #008db7;
}



#explicability .divider {
  width: 100%;
  height: 4px;
  margin-bottom: 96px;
  border-bottom: solid 2px #999;
  margin-top: 48px;
}

#explicability .resume {
  max-width: 60%;
  margin-bottom: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  margin-top: 160px;
}

#explicability .legend-force-chart {
  display: block;
  border-top: solid 1px #fafafa;  
}

#explicability .legend-force-chart .list {
  list-style: none;
  padding: 0;
}

#explicability .legend-force-chart .list .item-legend {
  display: inline;
}

#explicability .legend-force-chart .list .item-legend .color-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  display: inline-flex;
  margin: 0 10px;
  box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
}

#explicability .legend-force-chart .list .item-legend span {
  margin-bottom: 4px;
}

#explicability .legend-force-chart .list .item-legend#azul .color-item {
  background: #008db7;
}

#explicability .legend-force-chart .list .item-legend#rosa .color-item {
  background: #e73b94;
}

#explicability .legend-force-chart .list .item-legend#cinza .color-item,
#explicability .legend-model-chart .list .item-legend#cinza .color-item {
  background: #333;
}

#explicability .legend-force-chart .list .item-legend#vermelho .color-item,
#explicability .legend-model-chart .list .item-legend#vermelho .color-item {
  background: #ed2f30;
}

#explicability .legend-force-chart .list .item-legend#roxo .color-item {
  background: #666699;
}

#explicability .legend-force-chart .list .item-legend#verde .color-item {
  background: #6dbb9c;
}

#explicability .force-graph-component {
  border: solid 1px #eee;
  border-bottom: solid 2px #eee;
}

#explicability .source-chart span {
  font-style: italic;
}

#explicability .legend-gradient {
  display: flex;
}

#explicability .area-bar-code {
  margin: 72px auto;
}

#explicability .legend-gradient .gradient {
  background-image: linear-gradient(to right,#66669c, #ed2f30);
  width: 100px;
  height: 12px;
  margin: 0 16px;
  margin-top: 5px;
}


#explicability .legend-model-chart .list .item-legend {
  display: inline;
}

#explicability .legend-model-chart .list .item-legend .color-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  display: inline-flex;
  margin: 0 10px;
  box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
}

#explicability .legend-model-chart .list .item-legend span {
  margin-bottom: 4px;
}

#explicability .legend-model-chart .list .item-legend#azul .color-item {
  background: #008db7;
}

#explicability .legend-model-chart .list .item-legend#rosa .color-item {
  background: #e73b94;
}

#explicability .legend-model-chart .list .item-legend#roxo .color-item {
  background: #666699;
}

#explicability .legend-model-chart .list .item-legend#verde .color-item {
  background: #6dbb9c;
}

#explicability .box-1 .intro p,
#explicability .box-1 .intro h3 {
  display: block;
  padding-bottom: 16px auto;
}

#explicability .box-1 .intro  {
  padding-left: 32px;
  max-width: 45%;
  box-sizing: border-box;
}

#explicability .context-vis {
  padding: 42px;
}

#explicability .color-text,
#explicability .featured {
  color: #6dbb9c !important;
}

#explicability .featured-2 {
  color: #008db7;
}

#explicability .box-count-results {
  padding: 16px;
  border: solid 1px;
  width: 200px;
  font-family: 'Ubuntu', sans-serif !important;
}
#explicability .box-count-results .color {
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 1.5em;
}

#explicability .box-count-results.pink {
  border-color: #e73b94;
}

#explicability .box-count-results.pink .color, 
#explicability .text-pink-color{
  color: #e73b94 !important;
}

@media (max-width: 1220px) {
  #explicability .container-area,
  #explicability .container-left,
  #explicability .container-right,
  #explicability .container-flex {
    display: block;
    max-width: 90% !important;
    width: 90% !important;
  }

  #explicability .container-right {
    background-size: 90% !important;
    background-position: center !important; 
  }
}

@media (max-width: 940px)  {
  #explicability .legend-force-chart .list .item-legend {
    display: block;
    margin: 4px auto;
  }
}


.axis {
  font-size: 13px;
}

.axis path,
.axis line {
  fill: none;
  display: none;
}

.label {
  font-size: 13px;
}


#page-chart-search {
  background-image: url(/images/bg_4.jpg);
  background-size: 150% auto;
  background-position: center;
  height: 100%;
  width: 100%;
  margin-top: -20px;
}

#page-chart-search *{
  color: #fff !important
}

#page-chart-search .be-title-page{
  border-bottom: solid 1px #fff;
}

.force-graph-component {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.force-graph-component .links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.force-graph-component .nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.force-graph-component text {
  font-family: sans-serif;
  font-size: 10px;
}

.force-graph-component text{
  fill: #4e4f51 !important;
}

.force-graph-component .link {
  fill: none
}

.title-infographs {
  display: flex;
}

.title-infographs .detail-title {
  background-position: center;
  /*height: 180px;
  width: 230px;*/
  height: 230px;
  width: 295px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  vertical-align: middle;
}

.title-infographs .detail-title.d1 {
  background-image: url(/images/title_d01.png);
}
.title-infographs .detail-title.d2 {
  background-image: url(/images/title_d02.png);
}
.title-infographs .detail-title.d3 {
  background-image: url(/images/title_d03.png);
}
.title-infographs .detail-title.d4 {
  background-image: url(/images/title_d04.png);
}

.title-infographs .title-text {
  color: #707070;
  font-size: 32px;
  display: inline-flex;
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: bold;
}

.title-infographs .title-text span{
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: bold;
}

.title-infographs .title-text .color-1 {
  color: #008db7; 
}

.title-infographs .title-text .color-2 {
  color: #666699;
}  

.title-infographs .title-text .color-3 {
  color: #e73b94;
}

.title-infographs .title-text .color-4 {
  color: #cc3333;
}

@media (max-width: 600px)  {
  .title-infographs {
    display: block;
  }
  .title-infographs .detail-title {
    background-position: center;
    height: 230px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    vertical-align: middle;
  }
}


#scatterplot-chart-component .label {
  opacity: .1;
}

#scatterplot-chart-component .dot-stroke {
  stroke: #111;
  stroke-width: 1px;
  fill: none
}

#scatterplot-chart-component .tooltip {
  position: absolute;
  width: 200px;
  height: 28px;
  pointer-events: none;
}

#scatterplot-chart-component .tick text {
  font-size: 10px; 
  fill: #999;
  opacity: 0.1;
}

#scatterplot-chart-component .tick line {
  opacity: 0.2;
}

#scatterplot-chart-component .axis path,
#scatterplot-chart-component .axis line {
  fill: none;
  stroke: #4e4f51;
  display: block;
}

#scatterplot-chart-component text {
  font-family: 'Ubuntu', sans-serif;
}

#scatterplot-chart-component text.txt {
  font-size: 12px;
  color: #4e4f51;
}
#area-bar-code-chart-component .axis path,
#area-bar-code-chart-component .axis line {
  fill: none;
  stroke: #4e4f51;
  shape-rendering: crispEdges;
}

#area-bar-code-chart-component .dot {}

#area-bar-code-chart-component .dot-stroke {
  stroke: #111;
  stroke-width: 1px;
  fill: none
}

#area-bar-code-chart-component .tooltip {
  position: absolute;
  width: 200px;
  height: 28px;
  pointer-events: none;
}

#area-bar-code-chart-component .tick text {
  font-size: 10px;
  /* fill: #999 */
  opacity: 0;
}

#area-bar-code-chart-component .tick line {
  opacity: 0;
}

#area-bar-code-chart-component .axis path,
#area-bar-code-chart-component .axis line {
  fill: none;
  stroke: #4e4f51;
  opacity: 0;
}

#area-bar-code-chart-component text {
  font-family: 'Ubuntu', sans-serif;
}

#area-bar-code-chart-component text.txt {
  font-size: 12px;
  color: #4e4f51;
}
#table-component {
    display: block;
}

#table-component .table {
    border-collapse: collapse;
}

#table-component .table tr td {
    border-bottom: 1px solid #eee;
    padding: 12px 16px;
}

#table-component .table tr td.cos-sims {
    /* border-left: 1px solid #ddd; */
    text-align: right;
}

#table-component .table {
    border: #fff;
}

#table-component .table tr.th td {
    padding: 24px;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif !important;
    border-bottom: 2px solid #4e4f51;
}

.home-presentation {
    height: calc(100% - 118px);
}

#search-home {
    padding-top: 24px;
    padding-bottom: 100px;
    text-align: center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 100%;
}

#search-home-bg {
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 100%;
}

#search-home .tool-bar .link-page.link-featured {
  border: solid 1px rgba(255, 255, 255, .4);
  padding: 15px 16px;
}

#search-home .tool-bar {
  box-shadow: none;
}

#search-home .tool-bar * {
  /* color: #fff !important; */
}

#search-home .tool-bar .link-page {
  text-decoration: none;
  margin: 0;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;

  background: transparent;
  -webkit-transition-property: background;
  -webkit-transition-duration: 1s;
  transition-property: background;
  transition-duration: 1s;
}

#search-home .bible-home {
    display: none;
    margin: 0px auto;
    margin-top: 24px;
    max-width: 360px;
    opacity: .05;
}

#search-home.style-0, #search-home.style-3 {
	background-image: url(/images/grafismo.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}
#search-home.style-1 {
	background-image: url(/images/mulher-lendo.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 25%;
}
#search-home.style-2 {
	background-image: url(/images/homem-lendo.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 32%;
}

#content-home.style-2 * {
    color: #fff !important;
}

#content-home.style-2 .be-title-page {
    border-bottom: solid 1px #fff;
}

#search-home .box-all {
	display: block;
    position: relative;
    top: 20px;
    /*height: 210px;*/
    /*margin-top: -140px;*/
}

#search-home .area-serch-icon {
    display: inline;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}

#search-home .slogan-project,
#search-home .loading-text,
#search-home .input-react-fetch-auto-complete *,
#search-home .search *
{
	color: #4e4f51 !important;
	font-weight: normal;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4e4f51 !important;
}

#search-home .slogan-project {
	margin: 40px auto;
  font-size: 1.5em;
}

#search-home .logo {
	max-width: 240px;
	display: block;
	margin: 0px auto;
	margin-bottom: 40px;
}

#search-home .search {
  border: solid 1px #ccc;
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 0px;
  width: 60%;
  max-width: 540px;
  border-bottom: solid 2px #ccc;
  background: #fff;
}

#search-home .input-react-fetch-auto-complete {
	border: none !important;
  outline: none !important;
  width: 72%;
  background-color: transparent;
  margin-left: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 24px;
  font-size: 16px;
}

#search-home .loading-text {
	font-style: italic;
}

#search-home .box-suggestions {
	width: 40%;
    max-width: 500px;
	  margin: 20px auto;
    display: block;
    box-shadow: none;
    padding: 24px;
    text-align: left;
    position: relative;
    display: block;
    background: #fff;
}

#search-home .box-suggestions .result-suggestion {
    cursor: pointer;
}

#search-home .box-suggestions .result-suggestion .tag-result-name {
	display: table;
}

#search-home .box-suggestions .result-suggestion .tag-result-name * {
	display: table-cell;
    vertical-align: middle;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.person * {
    color: #008db7;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.place * {
    color: #6dbb9c;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.book * {
	color: #e73b94;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.concept * {
	color: #666699;
}

#search-home .box-suggestions .result-suggestion .tag-result-name svg {
	margin-right: 10px;
}

#search-home .box-suggestions .result-suggestion .tag-result-name .search-result-name {
	text-transform: uppercase;
}

#content-home {    
    padding: 80px;
}

#content-home .content-area {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

#content-home .content-image {
    width: 40%;
    height: auto;
    margin: 0;
}

#content-home .content-image img {
    display: block;
    width: 80%;
}

#content-home .content {
    width: 59%;
    margin: 0;
}

#search-home .button-evaluate {
  background: #008db7;
  padding: 24px;
  font-weight: bold;
  border-radius: 0;
  margin-top: 64px;
}

#search-home .button-evaluate * {
  color: #fff !important;
}

@media (max-width: 1362px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search-home .tool-bar .link-page.link-featured {
    padding: 16px 4px;
  }
  #search-home .tool-bar .link-page.link-featured span{
    font-size: 10px;
  }
}

#search-home .c2 {}

#search-home .c3 {
  display: none;
}

#search-home .c4 {
  display: none;
}

#search-home .be-divider {
  height: 1px;
  width: 40%;
  margin: 0 auto;
  padding: 0;
  background: #ddd;
  margin-top: 24px;
}

/* #search-home .area-indexes-book {
  background: #008db7;
} */


.insta-info {
  padding: 64px 0;
  text-align: center;
  border-top: solid 2px #576767;
}

.insta-info .be-title-footer,
.insta-info .be-title-footer * {
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: bold;
}

.insta-info .be-title-footer a {
  color: #008db7 !important;
}

.insta-info .new-section {
  margin-top: 64px;
}

@media (max-width: 1820px) {
  #search-home .be-divider {
    width: 100%;
  }
}

@media (max-height: 960px) {
  #search-home .c2 {
    display: none;
  }
  #search-home .c3 {
    display: block;
  }
}

@media (max-height: 830px) {
  #search-home .c2,
  #search-home .c3 {
    display: none;
  }
  #search-home .c4 {
    display: block;
  }

  #search-home {
    background-image: none !important;
  }
}

@media (max-height: 750px) {
  #search-home .be-divider,
  #search-home .activite.read,
  #search-home .c2,
  #search-home .c3,
  #search-home .c4 {
    display: none;
  }
}

@media (max-width: 1660px) and (min-height: 980px) {
  #search-home .c2,
  #search-home .c4 {
    display: none;
  }
  #search-home .c3 {
    display: block;
  }
}

@media (max-width: 1110px) {
  #search-home .be-divider,
  #search-home .activite.read,
  #search-home .c2,
  #search-home .c3,
  #search-home .c4 {
    display: none;
  }
}

@media (max-width: 1200px)  {
    #search-home {
        background-image: none !important;
    }
    #search-home .bible-home {
        display: block;
    }
}

@media (max-width: 920px)  {
    #content-home {
        background-image: none !important;
    }
	#content-home .content-area {
        display: block;
    }
    #content-home .content-image {
        width: 70%;
        display: block;
        margin: 0;
    }
	#search-home .search {
		width: 100%;
    }
    
    #content-home .content {
        width: 90%;
    }
}

@media (max-width: 800px)  {
  .insta-info .box-twitter {
    display: none;
    text-align: center;
  }
  
	#search-home .input-react-fetch-auto-complete {
		/* width: 100%; */
    }

	#search-home .box-suggestions {
		width: 80%;
		box-sizing: border-box;
	}
}

@media (max-width: 600px)  {
	#search-home {
		padding: 100px 20px;
        box-sizing: border-box;
        overflow: hidden;
    }
    #search-home .box-all {
        top: 0;
    }
    #search-home .box-all .logo {
        max-width: 90%;
    }
    #search-home .box-all .slogan-project {
        display: none;
    }
    .home-presentation {
        height: calc(100% - 174px) !important;
    }
}


#list-books .box-index-bible {
  display: block;
  width: 90%;
  margin: 0 auto; 
}

#list-books .box-index-bible.box-index-size-3,
#list-books .box-index-bible.box-index-size-4 {
  width: 100%;
}

#list-books .box-testament {
  display: inline-block;
  vertical-align: text-top;
  width: 20%;
  text-align: left;
  max-height: 400px;
  margin: 10px;
  background: rgba(255, 255, 255, .7);
  /* -webkit-box-shadow: -4px 5px 15px -9px rgba(0,0,0,0.18);  
  -moz-box-shadow: -4px 5px 15px -9px rgba(0,0,0,0.18);
  box-shadow: -4px 5px 15px -9px rgba(0,0,0,0.18); */
}

#list-books .box-testament.box-size-3 {
  width: 40%;
}

#list-books .box-testament.box-size-4 {
  width: 48%;
  margin: auto 1%;
}

#list-books .title-testament {
  text-transform: uppercase;
  color: #008db7;
  border-bottom: solid 1px  #008db7;
  padding-bottom: 11px;
  font-size: 14px;
}

#list-books .books-bible {
  display: block;
}

#list-books .books-bible p {
  margin: 3px;
  font-size: 13px;
  cursor: pointer;
} 

#list-books .columns-2 {
  -webkit-column-count:2;
  column-count:2;
}

#list-books .columns-3 {
  -webkit-column-count:3;
  column-count:3;
}

#list-books .columns-4 {
  -webkit-column-count:4;
  column-count:4;
}

#list-books .link-bible{
  text-decoration: none;
}
#step-initial-form .slider {
  background-color: #f3f3f3;
  padding-top: 60px;

  background-image: url(/images/concordancia_biblica.png) !important;
  background-size: 20% auto;
  background-position: left bottom;
  background-repeat: no-repeat;
}

#step-initial-form .presentation {
  background-color: #008db7;
  padding-top: 60px;

  background-image: url(/images/banner_evaluate.png) !important;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 628px;
}

#step-initial-form .evaluate.content {
  text-align: center;
  padding: 60px 30px;
}

#step-initial-form .evaluate.content p {
  margin: 24px 0;
  margin-bottom: 64px;
}

#step-initial-form .evaluate.content .be-title-page {
  width: 100%;
}

#step-initial-form .slider .content * {
  color: #4e4f51 !important;
}

#step-initial-form .slider .content {
  background-image: url(/images/avaliacao.png) !important;
  background-size: 50% auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 450px;
}

#step-initial-form .slider-search {
  background-color: #666699;
  padding-top: 60px;
}

#step-initial-form .slider-search h2,
#step-initial-form .slider-search p {
  color: #fff !important;
}

#step-initial-form .slider .content label{
  font-size: .7em;
  text-transform: uppercase;
  text-align: left;
}
#step-initial-form .slider .content a{
  text-decoration: none;
  border-color: #4e4f51;
}
#step-initial-form .slider .content p{
  max-width: 650px;
}
#step-initial-form .slider .content h2{
  margin-top: 0px;
}
#step-initial-form .slider .content h3,.slider .content h4{
  color: #000;
}
#step-initial-form .slider .content .vers{
  font-style: italic;
}
#step-initial-form .slider .content .be-title-page {
  border-bottom: solid 1px #4e4f51;
  max-width: 50%;
}

#step-initial-form .content.last {
  padding-bottom: 60px;
}
#step-initial-form .slider .content p, .slider .content h2, .slider .content h3 {
	color: #000
}
#step-initial-form .evaluation .content {
	text-align: center;
} 
#step-initial-form .evaluation h2 {
	padding-bottom: 10px;
	margin-top: 50px;
	margin-bottom: 30px;
	width: 250px;
}
a {
	text-decoration: none;
}
.area-person {
	display: inline-flex;
    padding: 0 30px;
}
.area-person img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 30px;
}
.area-person .area-info{
  display: inline-block;
  width: 90%;
}
.area-person .area-info .name{
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
}
.area-person .area-info .description{
  font-style: italic;
}

#step-initial-form #footer {
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 54px;
  background: rgba(255, 255, 255, .9); 
  padding: 4px 0;
  box-sizing: border-box;
  border-top: solid 1px #ccc;
}

#step-initial-form #footer * {
  color: #e73b94 !important
}

#step-initial-form .box-step-evaluation-final {
  background: #008db7;
  padding: 32px;
  /* text-align: center */
}

#step-initial-form .box-step-evaluation-final * {
  color: #fff !important
}

#step-initial-form .button-evaluation {
  background: #666699;
  border-radius: 0;
  padding: 16px 24px;
}

#step-initial-form .button-evaluation * {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}


@media (max-width: 2170px) {
  #step-initial-form .slider {
    padding-top: 0px;
    background-size: 15% auto;
    background-position: left center;
  }
}

@media (max-width: 1860px) {
  #step-initial-form .slider {
    background-image: none !important;
  }
}

@media (max-width: 1556px) {
  #step-initial-form .slider .content p {
    width: 50%;
  }
}

@media (max-width: 1380px) {
  #step-initial-form .slider .content {
    background-size: 40% auto;
  }
}

@media (max-width: 1210px) {
  #step-initial-form .presentation {  
    background-image: url(/images/banner_evaluate_responsive.png) !important;
    background-size: auto 90%;
    /* height: auto; */
  }
}

#form-evaluation {

}

#form-evaluation #info-demo .form-control {
  border: solid 1px #ddd;
    padding: 24px;
    margin: 16px;
}

#form-evaluation .box-step-evaluation {
  margin: 24px auto;
}


#search-input {
  text-align: center;
}

#search-input .search *
{
	color: #4e4f51 !important;
	font-weight: normal;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4e4f51 !important;
}

#search-input .search {
  border: solid 1px #ccc;
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 0px;
  width: 60%;
  max-width: 540px;
  border-bottom: solid 2px #ccc;
  background: #fff;
}

#search-input .area-serch-icon {
    display: inline;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}

#search-input .input-react-fetch-auto-complete {
	border: none !important;
    outline: none !important;
    width: 72%;
    background-color: transparent;
    margin-left: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 24px;
    font-size: 16px;
}

#search-input .loading-text {
	font-style: italic;
}

#search-input .box-suggestions {
	width: 40%;
    max-width: 500px;
	  margin: 20px auto;
    display: block;
    box-shadow: none;
    padding: 24px;
    text-align: left;
    position: relative;
    display: block;
    background: #fff;
}

#search-input .box-suggestions .result-suggestion {
    cursor: pointer;
}

#search-input .box-suggestions .result-suggestion .tag-result-name {
	display: table;
}

#search-input .box-suggestions .result-suggestion .tag-result-name * {
	display: table-cell;
    vertical-align: middle;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.person * {
    color: #008db7;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.place * {
    color: #6dbb9c;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.book * {
	color: #e73b94;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.concept * {
	color: #666699;
}

#search-input .box-suggestions .result-suggestion .tag-result-name svg {
	margin-right: 10px;
}

#search-input .box-suggestions .result-suggestion .tag-result-name .search-result-name {
	text-transform: uppercase;
}

@media (max-width: 1362px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search-input .tool-bar .link-page.link-featured {
    padding: 16px 4px;
  }
  #search-input .tool-bar .link-page.link-featured span{
    font-size: 10px;
  }
}

#search-input .c3 {
  display: none;
}

#search-input .c4 {
  display: none;
}

@media (max-width: 324px)  {
  #head .area-serch-icon {
    position: absolute;
    right: -10px;
  }
}

#evaluate-header header {
  /* background: #4e4f51; */
  background: #fff !important;
  color: #4e4f51;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
  text-align: center;
}

#evaluate-header header > div {
  text-align: center;
  width: auto;
  margin: 0 auto;
}

#evaluate-header header *, #evaluate-header * {
  color: #4e4f51 !important;
  font-weight: normal;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4e4f51 !important;
}
#search-home .slogan-project {
	margin: 40px auto;
}

#evaluate-header .options {
  right: 24px;
  position: absolute;
}
#menu-appbar > div {
  top: 56px !important;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12) !important;
}

#evaluate-header .logo {
  max-height: 48px;
  margin-right: 16px;
  box-sizing: border-box;
}

#evaluate-header .search {
  border-radius: 0px;
  border: solid 1px #ccc;
  width: 40%;
}

#evaluate-header .area-serch-icon {
  display: inline;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

#evaluate-header .content {
  max-width: 80%;
  text-align: left;
  width: 1460px;
  margin: 0 auto !important;
  left: -100px;
  position: relative;
}
#evaluate-header .translate {
  position: absolute;
  right: 16px;
}

#evaluate-header .translate button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: .8em;
}

#evaluate-header .translate button:hover{
  /* font-size: .85em; */
  color: #008db7;
}

#evaluate-header .link-page {
  text-decoration: none;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 24px;
}

#evaluate-header .link-page .icon-button {
  font-size: 16px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}

@media (max-width: 1720px)  {
  #evaluate-header .content {
    max-width: 80%;
    text-align: left;
    width: 80%;
    margin: 0 !important;
    top: -82px;
    position: relative;
  }

  #evaluate-header .search {
    position: absolute;
    top: 65px;
    width: 84%;
    display: block;
    margin: 0 auto;
    left: 120px;
  }
}

@media (max-width: 550px)  {
  #evaluate-header .link-page {
    display: none;
  }
}

#search.evaluate {
  padding-bottom: 400px;
}

#search.evaluate #area-evaluate-questions {
  position: fixed;
  bottom: 0;
  width: 100%;
  border: solid 1px #eee;
  background: #333;
  box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.32);
}

#search.evaluate #area-evaluate-questions.results {
  background: #008db7;
}

#search.evaluate #area-evaluate-questions.explanation {
  background: #58a687;
}

#search.evaluate #area-evaluate-questions.trust {
  background: #666699;
}

#search.evaluate #area-evaluate-questions.next {
  background: #737a84;
}

#search.evaluate #area-evaluate-questions.comment {
  background: #e73b94;
}

#search.evaluate #area-evaluate-questions.done {
  background: #58a687;
}

#search.evaluate #area-evaluate-questions * {
  color: #fff !important;
  border-color: #fff;
}

#search.evaluate #area-evaluate-questions .form-evaluate > label {
  display: inline-block;
}

#search.evaluate #area-evaluate-questions .form-evaluate > .field {
  display: inline-block;
}

#search.evaluate #area-evaluate-questions .form-evaluate > .field .item{
  margin-right: 64px;
}

#search.evaluate #area-evaluate-questions textarea.comment {
  outline: none;
  display: block;
  width: 100%;
  min-height: 80px;
  resize: none;
  margin: 20px auto !important;
  color: #111 !important;
  font-size: 14px;
}

#search.evaluate #area-evaluate-questions .comment-evaluate .button-evaluation {
  padding: 16px;
  background: rgba(0, 0, 0, .5);
  border-radius: 0;
  margin-top: 12px;
  align-items: flex-end;
}

#search.evaluate #area-evaluate-questions .comment-evaluate .button-evaluation * {
  color: #fff !important;
}

#search.evaluate #area-evaluate-questions .area-button-comment {
  display: grid;
}


#search.evaluate #area-evaluate-questions .finished-evaluate {
  display: block;
  margin: 0 auto;
  text-align: center;
}

#search.evaluate #area-evaluate-questions .finished-evaluate .button-evaluation {
  padding: 24px;
  background: #fff;
  border-radius: 0;
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;
}

#search.evaluate #area-evaluate-questions .finished-evaluate .button-evaluation * {
  color: #333 !important;
}

#search.evaluate #footer {
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 54px;
  background: rgba(255, 255, 255, .9); 
  /*background: #1C2A39;*/
  padding: 4px 0;
  box-sizing: border-box;
  border-top: solid 1px #ccc;
  margin-top: 16px;
}

#search.evaluate #footer * {
  color: #e73b94 !important
}

#search.evaluate #area-evaluate-questions .be-title-page {
  margin: 0;
  padding-top: 0;
  padding-bottom: 8px;
}

#search.evaluate #area-evaluate-questions .be-title-page.final-title {
  margin: 32px auto;
}

@media (max-width: 850px) {
  #search.evaluate #search-tool-bar .tool-bar {
    height: 120px !important;
  }

  #search.evaluate #evaluate-header header > div {
    padding-top: 48px;
    width: auto;
    margin: auto;
  }
}

@media (max-width: 648px) {
  #search.evaluate #area-evaluate-questions .be-title-page {
    width: 100%;
    font-size: 20px;
  }
}


#logs {
  display: block;
  margin: 0;
}

#logs .logo-logs {
  text-align: center;
  margin: 0 auto;
  padding-top: 48px;
  display: block;
  margin-bottom: 48px;
  box-shadow: 0px 0px 23px -2px rgba(0,0,0,0.15);
}

#logs .logo-logs .logo {
  max-width: 140px;
}

#logs .box-logs-dashboard {
  max-width: 1080px;
  margin: 0 auto;
}

#logs .row-log  {
  border-top: solid 1px #eee;
  padding: 8px 4px; 
}

#logs .row-log .destaque {
  font-weight: bold;
}

#logs .box-logs-last-searches {
  max-width: 800px;
  display: block;
  margin: 0 auto
}

.header-logs-biblexplorer {
  display: flex;
  justify-content: center;
}

.header-logs-biblexplorer .item-header-logs {
  margin: 12px;
  padding: 8px;
  border-bottom: solid 1px transparent;
}

.header-logs-biblexplorer .item-header-logs:hover {
  margin: 12px;
  padding: 8px;
  color: #e73b94;
}
#area-charts-logs {
  display: block;
}

#area-charts-logs h2 {
  text-align: center;
}

#area-charts-logs .area-box-charts {
  display: flex;
}
.area-pie-chart {
  display: flex;
  align-items: center;
  margin: 24px auto;
  border-bottom: solid 1px #eee;
  max-width: 520px;
}

.area-pie-chart .color-item-pie {
  height: 12px;
  width: 12px;
  background-color: #000;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 8px;
}


.area-pie-chart .item-pie-1 .color-item-pie {
  background-color: #6dbb9c;
}

.area-pie-chart .item-pie-2 .color-item-pie {
  background-color: #ffcc66;
}

.area-pie-chart .item-pie-3 .color-item-pie {
  background-color: #008db7;
}

.area-pie-chart .item-pie-4 .color-item-pie {
  background-color: #666699;
}

.area-pie-chart .item-pie-5 .color-item-pie {
  background-color: #e73b94;
}

.area-pie-chart .item-pie-6 .color-item-pie {
  background-color: #cc3333;
}
#area-word-cloud {
  display: block;
  margin-top: 48px;
}

#area-word-cloud h2 {
  text-align: center;
}

.auth-input {
  display: block;
  max-width: 1080;
  margin: 0 auto;
  text-align: center;
  min-height: 400px;
}
.auth-input input {
  outline: none;
  height: 48px;
  width: 240px;
  padding: 16px;
  box-sizing: border-box;
}

.auth-input button  {
  outline: none;
  background-color: #008db7;
  color: #fff;
  text-transform: uppercase;
  border: none;
  padding: 8px 24px;
  font-size: 18px;
  font-weight: bold;
  display: block;
  width: 240px;
  height: 48px;
  margin: 24px auto;
}
#logs-evaluation {
  display: block;
  margin: 0 auto;
}

#logs-evaluation .logo-logs {
  text-align: center;
  margin: 0 auto;
  padding-top: 48px;
  display: block;
  margin-bottom: 48px;
  box-shadow: 0px 0px 23px -2px rgba(0,0,0,0.15);
}

#logs-evaluation .logo-logs .logo {
  max-width: 160px;
}

#logs-evaluation .box-logs-dashboard {
  max-width: 1080px;
  margin: 0 auto;
}

#logs-evaluation .box-logs {
   border-bottom: solid 2px #eee; 
}

.header-logs-biblexplorer {
  display: flex;
  justify-content: center;
}

.header-logs-biblexplorer .item-header-logs {
  margin: 12px;
  padding: 8px;
  border-bottom: solid 1px transparent;
}

.header-logs-biblexplorer .item-header-logs:hover {
  margin: 12px;
  padding: 8px;
  color: #666699;
}
.card-dashboard {
  border: solid 1px #eee;
  margin: 24px;
  padding: 16px;
  text-align: center;
}
.card-dashboard p.featured-number {
  font-size: 24px;
  font-weight: bold;
}

.card-dashboard p.label-number {
  text-transform: uppercase;
  font-size: 14px;
}

.card-dashboard:nth-child(1) .featured-number {
  color: #008db7;
}

.card-dashboard:nth-child(1) {
  border-left: solid 1px #008db7;
  border-bottom: solid 6px #008db7;
}

.card-dashboard:nth-child(2) .featured-number {
  color: #e73b94;
}

.card-dashboard:nth-child(2) {
  border-left: solid 1px #e73b94;
  border-bottom: solid 6px #e73b94;
}

.card-dashboard:nth-child(3) .featured-number {
  color: #ffcc66;
}

.card-dashboard:nth-child(3) {
  border-left: solid 1px #ffcc66;
  border-bottom: solid 6px #ffcc66;
}

.card-dashboard:nth-child(4) .featured-number {
  color: #6dbb9c;
}

.card-dashboard:nth-child(4) {
  border-left: solid 1px #6dbb9c;
  border-bottom: solid 6px #6dbb9c;
}

.card-dashboard:nth-child(5) .featured-number {
  color: #666699;
}

.card-dashboard:nth-child(5) {
  border-left: solid 1px #666699;
  border-bottom: solid 6px #666699;
}

.card-dashboard:nth-child(6) .featured-number {
  color: #cc3333;
}

.card-dashboard:nth-child(6) {
  border-left: solid 1px #cc3333;
  border-bottom: solid 6px #cc3333;
}


#area-cards {
  display: block;
  margin: 0 auto;
  width: 100%;
}

#area-cards .box-cards {
  display: flex;
  margin-top: 24px;
  padding-top: 8px;
  justify-content: center;
  margin-bottom: 48px;
}
#area-charts {
  display: block;
}

#area-charts h2 {
  text-align: center;
}

#area-charts .area-box-charts {
  display: flex;
}
.comments {
  display: block;
}

.comments h3 {
  text-align: center;
}

.comments .comment {
  font-style: italic;
  border-left: solid 1px #eee;
  border-bottom: solid 1px #eee;
  padding: 16px;
  margin: 8px;
}
