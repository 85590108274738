
#page-chart-search {
  background-image: url(/images/bg_4.jpg);
  background-size: 150% auto;
  background-position: center;
  height: 100%;
  width: 100%;
  margin-top: -20px;
}

#page-chart-search *{
  color: #fff !important
}

#page-chart-search .be-title-page{
  border-bottom: solid 1px #fff;
}

.force-graph-component {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.force-graph-component .links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.force-graph-component .nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.force-graph-component text {
  font-family: sans-serif;
  font-size: 10px;
}

.force-graph-component text{
  fill: #4e4f51 !important;
}

.force-graph-component .link {
  fill: none
}
