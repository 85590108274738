@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Poppins|Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fdfdfd;
}

body * {
  font-family: 'Lato', 'Roboto', sans-serif !important;
  /* font-weight: 300 */
  color: #4e4f51;
}

.page {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: -moz-calc(100% - 168px);
  height: -webkit-calc(100% - 168px);
  height: -o-calc(100% - 168px);
  height: calc(100% - 168px);
  box-sizing: border-box;
}

.page iframe {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
}


h1,
h2,
h3,
h4,
h5 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  color: #4e4f51 !important;
}

p,
span {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
}

.content {
  max-width: 1460px;
  margin: 0 auto;
  display: block
}

.be-title {
  font-family: "Ubuntu", sans-serif;
  font-size: 30px;
}

.be-title-page {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 24px;
  border-bottom: solid 1px #737a84;
  padding: 20px 0;
  width: 60%;
}

html,
body,
#app,
#app>div {
  height: 100%
}

.be-card {
  border-radius: 0 !important;
}

.be-color-purple {
  color: #666699;
}

.be-color-blue {
  color: #008db7;
}

.be-color-green {
  color: #6dbb9c;
}

.be-color-yellow {
  color: #ffcc66;
}

.be-color-pink {
  color: #e73b94;
}

.be-color-red {
  color: #cc3333;
}

.be-featured {
  font-weight: bold;
}

@media (max-width: 1920px) {
  .content {
    max-width: 80% !important;
    margin: 0 auto;
    display: block
  }
}