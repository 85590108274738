#explicability {
  
}

#explicability h2, #explicability h3 {
  width: 80%;
  margin-top: 42px;
}

#explicability .container-left {
  max-width: 59%;
}

#explicability .container-right.chart {
  margin-left: 72px;
  padding-top: 96px;
}

#explicability .container-right {
  max-width: 40%;
  height: 500px;
  display: inline-block;
  width: 40%;
}

#explicability .container-area,
#explicability .container-flex  {
  display: flex;
}

#explicability .container-block {
  display: block;
}

#explicability .area-1 .container-right {
  background-image: url(/images/explicabilidade-img-1.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .area-2 .container-right {
  background-image: url(/images/termos_relacionados.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .area-3 .container-right {
  background-image: url(/images/concordancia_biblica.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .area-4 .container-right.img-1 {
  background-image: url(/images/calculos.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}

#explicability .container-right.img-2 {
  background-image: url(/images/grafismo_metodo.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
}


#explicability .box-1 .similar-terms-text {
  max-width: 59%;
}

#explicability .color-text,
#explicability .featured {
  color: #6dbb9c !important;
}

#explicability .featured-2 {
  color: #008db7;
}

#explicability .box-count-results {
  padding: 16px;
  border: solid 1px;
  width: 200px;
  font-family: 'Ubuntu', sans-serif !important;
}
#explicability .box-count-results .color {
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 1.5em;
}

#explicability .box-count-results.pink {
  border-color: #e73b94;
}

#explicability .box-count-results.pink .color, 
#explicability .text-pink-color{
  color: #e73b94 !important;
}

#explicability .box-count-results.purple {
  border-color: #666699;
}

#explicability .box-count-results.purple .color{
  color: #666699;
}

#explicability .box-count-results.green {
  border-color: #6dbb9c;
}

#explicability .box-count-results.green .color{
  color: #6dbb9c;
}

#explicability .box-count-results.blue {
  border-color: #6dbb9c;
}

#explicability .box-count-results.blue .color{
  color: #008db7;
}



#explicability .divider {
  width: 100%;
  height: 4px;
  margin-bottom: 96px;
  border-bottom: solid 2px #999;
  margin-top: 48px;
}

#explicability .resume {
  max-width: 60%;
  margin-bottom: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  margin-top: 160px;
}

#explicability .legend-force-chart {
  display: block;
  border-top: solid 1px #fafafa;  
}

#explicability .legend-force-chart .list {
  list-style: none;
  padding: 0;
}

#explicability .legend-force-chart .list .item-legend {
  display: inline;
}

#explicability .legend-force-chart .list .item-legend .color-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  display: inline-flex;
  margin: 0 10px;
  -webkit-box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
  -moz-box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
  box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
}

#explicability .legend-force-chart .list .item-legend span {
  margin-bottom: 4px;
}

#explicability .legend-force-chart .list .item-legend#azul .color-item {
  background: #008db7;
}

#explicability .legend-force-chart .list .item-legend#rosa .color-item {
  background: #e73b94;
}

#explicability .legend-force-chart .list .item-legend#cinza .color-item,
#explicability .legend-model-chart .list .item-legend#cinza .color-item {
  background: #333;
}

#explicability .legend-force-chart .list .item-legend#vermelho .color-item,
#explicability .legend-model-chart .list .item-legend#vermelho .color-item {
  background: #ed2f30;
}

#explicability .legend-force-chart .list .item-legend#roxo .color-item {
  background: #666699;
}

#explicability .legend-force-chart .list .item-legend#verde .color-item {
  background: #6dbb9c;
}

#explicability .force-graph-component {
  border: solid 1px #eee;
  border-bottom: solid 2px #eee;
}

#explicability .source-chart span {
  font-style: italic;
}

#explicability .legend-gradient {
  display: flex;
}

#explicability .area-bar-code {
  margin: 72px auto;
}

#explicability .legend-gradient .gradient {
  background-image: linear-gradient(to right,#66669c, #ed2f30);
  width: 100px;
  height: 12px;
  margin: 0 16px;
  margin-top: 5px;
}


#explicability .legend-model-chart .list .item-legend {
  display: inline;
}

#explicability .legend-model-chart .list .item-legend .color-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  display: inline-flex;
  margin: 0 10px;
  -webkit-box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
  -moz-box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
  box-shadow: 2px 1px 0px 2px rgba(0,0,0,0.02);
}

#explicability .legend-model-chart .list .item-legend span {
  margin-bottom: 4px;
}

#explicability .legend-model-chart .list .item-legend#azul .color-item {
  background: #008db7;
}

#explicability .legend-model-chart .list .item-legend#rosa .color-item {
  background: #e73b94;
}

#explicability .legend-model-chart .list .item-legend#roxo .color-item {
  background: #666699;
}

#explicability .legend-model-chart .list .item-legend#verde .color-item {
  background: #6dbb9c;
}

#explicability .box-1 .intro p,
#explicability .box-1 .intro h3 {
  display: block;
  padding-bottom: 16px auto;
}

#explicability .box-1 .intro  {
  padding-left: 32px;
  max-width: 45%;
  box-sizing: border-box;
}

#explicability .context-vis {
  padding: 42px;
}

#explicability .color-text,
#explicability .featured {
  color: #6dbb9c !important;
}

#explicability .featured-2 {
  color: #008db7;
}

#explicability .box-count-results {
  padding: 16px;
  border: solid 1px;
  width: 200px;
  font-family: 'Ubuntu', sans-serif !important;
}
#explicability .box-count-results .color {
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 1.5em;
}

#explicability .box-count-results.pink {
  border-color: #e73b94;
}

#explicability .box-count-results.pink .color, 
#explicability .text-pink-color{
  color: #e73b94 !important;
}

@media (max-width: 1220px) {
  #explicability .container-area,
  #explicability .container-left,
  #explicability .container-right,
  #explicability .container-flex {
    display: block;
    max-width: 90% !important;
    width: 90% !important;
  }

  #explicability .container-right {
    background-size: 90% !important;
    background-position: center !important; 
  }
}

@media (max-width: 940px)  {
  #explicability .legend-force-chart .list .item-legend {
    display: block;
    margin: 4px auto;
  }
}
