#home {
  display: block;
  margin: 0;
  padding: 0;
  background-color: #008db7;
}

#home .content * {
  color: #fff
}

#home .content {
  max-width: 1280px;
  text-align: center;
  padding-top: 64px;
}

#home .content .banner {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

#home .content .slogan-project {
  font-size: 32px;
  font-weight: bold;
}

@media (max-width: 1280px) {
  #home {
    max-width: 100%;
  }

  #home .content {
    min-height: 560px;
    padding-top: 48px;
  }
}

@media (max-width: 940px) {
  #home .content {
    max-width: 90% !important;
    min-height: 420px;
    padding-top: 36px;
  }

  #home .content .banner {
    width: 100%;
  }
}

@media (max-width: 720px) {
  #home .content {
    max-width: 100% !important;
    min-height: 560px;
  }
}