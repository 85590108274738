.title-infographs {
  display: flex;
}

.title-infographs .detail-title {
  background-position: center;
  /*height: 180px;
  width: 230px;*/
  height: 230px;
  width: 295px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  vertical-align: middle;
}

.title-infographs .detail-title.d1 {
  background-image: url(/images/title_d01.png);
}
.title-infographs .detail-title.d2 {
  background-image: url(/images/title_d02.png);
}
.title-infographs .detail-title.d3 {
  background-image: url(/images/title_d03.png);
}
.title-infographs .detail-title.d4 {
  background-image: url(/images/title_d04.png);
}

.title-infographs .title-text {
  color: #707070;
  font-size: 32px;
  display: inline-flex;
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: bold;
}

.title-infographs .title-text span{
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: bold;
}

.title-infographs .title-text .color-1 {
  color: #008db7; 
}

.title-infographs .title-text .color-2 {
  color: #666699;
}  

.title-infographs .title-text .color-3 {
  color: #e73b94;
}

.title-infographs .title-text .color-4 {
  color: #cc3333;
}

@media (max-width: 600px)  {
  .title-infographs {
    display: block;
  }
  .title-infographs .detail-title {
    background-position: center;
    height: 230px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    vertical-align: middle;
  }
}
