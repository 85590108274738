#search-input {
  text-align: center;
}

#search-input .search *
{
	color: #4e4f51 !important;
	font-weight: normal;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4e4f51 !important;
}

#search-input .search {
  border: solid 1px #ccc;
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 0px;
  width: 60%;
  max-width: 540px;
  border-bottom: solid 2px #ccc;
  background: #fff;
}

#search-input .area-serch-icon {
    display: inline;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}

#search-input .input-react-fetch-auto-complete {
	border: none !important;
    outline: none !important;
    width: 72%;
    background-color: transparent;
    margin-left: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 24px;
    font-size: 16px;
}

#search-input .loading-text {
	font-style: italic;
}

#search-input .box-suggestions {
	width: 40%;
    max-width: 500px;
	  margin: 20px auto;
    display: block;
    box-shadow: none;
    padding: 24px;
    text-align: left;
    position: relative;
    display: block;
    background: #fff;
}

#search-input .box-suggestions .result-suggestion {
    cursor: pointer;
}

#search-input .box-suggestions .result-suggestion .tag-result-name {
	display: table;
}

#search-input .box-suggestions .result-suggestion .tag-result-name * {
	display: table-cell;
    vertical-align: middle;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.person * {
    color: #008db7;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.place * {
    color: #6dbb9c;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.book * {
	color: #e73b94;
}

#search-input .box-suggestions .result-suggestion .tag-result-name.concept * {
	color: #666699;
}

#search-input .box-suggestions .result-suggestion .tag-result-name svg {
	margin-right: 10px;
}

#search-input .box-suggestions .result-suggestion .tag-result-name .search-result-name {
	text-transform: uppercase;
}

@media (max-width: 1362px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search-input .tool-bar .link-page.link-featured {
    padding: 16px 4px;
  }
  #search-input .tool-bar .link-page.link-featured span{
    font-size: 10px;
  }
}

#search-input .c3 {
  display: none;
}

#search-input .c4 {
  display: none;
}

@media (max-width: 324px)  {
  #head .area-serch-icon {
    position: absolute;
    right: -10px;
  }
}
