@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Poppins|Ubuntu');

#gallery {
  background: #fff;
  background-size: 150% auto;
  background-position: center;
  padding-top: 20px;
  box-sizing: border-box;
  padding-bottom: 50px;
}

#gallery .card-gallery-item {
  display: inline-block;
  margin: 10px;
  width: 280px;
  margin-bottom: 64px;
  border: solid 1px #f3f3f3;
  border-bottom: solid 4px #eee;
  cursor: pointer;
}

#gallery .area-media {
  height: 160px;
}

#gallery .area-content {
  height: 80px;
}

#gallery h1, 
#gallery h2, 
#gallery h3, 
#gallery h4 {
  font-family: 'Lato', sans-serif !important;
  font-weight: 300 !important;
}

#gallery .card-gallery-item h1, 
#gallery .card-gallery-item h2, 
#gallery .card-gallery-item h3, 
#gallery .card-gallery-item h4 {
  color: #006699;
}

#gallery .card-gallery-item .MuiPaper-elevation1-20 {
  box-shadow: none !important;
}

#visualiation-page {
  /* height: 100%; */
}

#visualiation-page .tool-bar {
  /* background-color: #6dbb9c;
  background-color: #008db7;
  background-color: #e73b94;
  background-color: #ffcc66;
  background-color: #cc3333;
  background-color: #7c6491;
  background-color: #fe863d;
  background-color: #c8c94d;
  background-color: #a2766e;
  background-color: #737a84; 
  background-color: #666699;*/
  background-color: #008db7;
}

#visualiation-page .tool-bar * {
  color: #fff !important;
}

#visualiation-page .tool-bar .message {
  font-size: .85em;
}

#visualiation-page .tool-bar .link-page {
  text-decoration: none;
  margin: 0 20px;
  text-transform: uppercase;
  font-size: .8em;
  color: #fff;
}