.auth-input {
  display: block;
  max-width: 1080;
  margin: 0 auto;
  text-align: center;
  min-height: 400px;
}
.auth-input input {
  outline: none;
  height: 48px;
  width: 240px;
  padding: 16px;
  box-sizing: border-box;
}

.auth-input button  {
  outline: none;
  background-color: #008db7;
  color: #fff;
  text-transform: uppercase;
  border: none;
  padding: 8px 24px;
  font-size: 18px;
  font-weight: bold;
  display: block;
  width: 240px;
  height: 48px;
  margin: 24px auto;
}