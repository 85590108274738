#area-cards {
  display: block;
  margin: 0 auto;
  width: 100%;
}

#area-cards .box-cards {
  display: flex;
  margin-top: 24px;
  padding-top: 8px;
  justify-content: center;
  margin-bottom: 48px;
}