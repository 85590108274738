#step-initial-form .slider {
  background-color: #f3f3f3;
  padding-top: 60px;

  background-image: url(/images/concordancia_biblica.png) !important;
  background-size: 20% auto;
  background-position: left bottom;
  background-repeat: no-repeat;
}

#step-initial-form .presentation {
  background-color: #008db7;
  padding-top: 60px;

  background-image: url(/images/banner_evaluate.png) !important;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 628px;
}

#step-initial-form .evaluate.content {
  text-align: center;
  padding: 60px 30px;
}

#step-initial-form .evaluate.content p {
  margin: 24px 0;
  margin-bottom: 64px;
}

#step-initial-form .evaluate.content .be-title-page {
  width: 100%;
}

#step-initial-form .slider .content * {
  color: #4e4f51 !important;
}

#step-initial-form .slider .content {
  background-image: url(/images/avaliacao.png) !important;
  background-size: 50% auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 450px;
}

#step-initial-form .slider-search {
  background-color: #666699;
  padding-top: 60px;
}

#step-initial-form .slider-search h2,
#step-initial-form .slider-search p {
  color: #fff !important;
}

#step-initial-form .slider .content label{
  font-size: .7em;
  text-transform: uppercase;
  text-align: left;
}
#step-initial-form .slider .content a{
  text-decoration: none;
  border-color: #4e4f51;
}
#step-initial-form .slider .content p{
  max-width: 650px;
}
#step-initial-form .slider .content h2{
  margin-top: 0px;
}
#step-initial-form .slider .content h3,.slider .content h4{
  color: #000;
}
#step-initial-form .slider .content .vers{
  font-style: italic;
}
#step-initial-form .slider .content .be-title-page {
  border-bottom: solid 1px #4e4f51;
  max-width: 50%;
}

#step-initial-form .content.last {
  padding-bottom: 60px;
}
#step-initial-form .slider .content p, .slider .content h2, .slider .content h3 {
	color: #000
}
#step-initial-form .evaluation .content {
	text-align: center;
} 
#step-initial-form .evaluation h2 {
	padding-bottom: 10px;
	margin-top: 50px;
	margin-bottom: 30px;
	width: 250px;
}
a {
	text-decoration: none;
}
.area-person {
	display: inline-flex;
    padding: 0 30px;
}
.area-person img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 30px;
}
.area-person .area-info{
  display: inline-block;
  width: 90%;
}
.area-person .area-info .name{
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
}
.area-person .area-info .description{
  font-style: italic;
}

#step-initial-form #footer {
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 54px;
  background: rgba(255, 255, 255, .9); 
  padding: 4px 0;
  box-sizing: border-box;
  border-top: solid 1px #ccc;
}

#step-initial-form #footer * {
  color: #e73b94 !important
}

#step-initial-form .box-step-evaluation-final {
  background: #008db7;
  padding: 32px;
  /* text-align: center */
}

#step-initial-form .box-step-evaluation-final * {
  color: #fff !important
}

#step-initial-form .button-evaluation {
  background: #666699;
  border-radius: 0;
  padding: 16px 24px;
}

#step-initial-form .button-evaluation * {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}


@media (max-width: 2170px) {
  #step-initial-form .slider {
    padding-top: 0px;
    background-size: 15% auto;
    background-position: left center;
  }
}

@media (max-width: 1860px) {
  #step-initial-form .slider {
    background-image: none !important;
  }
}

@media (max-width: 1556px) {
  #step-initial-form .slider .content p {
    width: 50%;
  }
}

@media (max-width: 1380px) {
  #step-initial-form .slider .content {
    background-size: 40% auto;
  }
}

@media (max-width: 1210px) {
  #step-initial-form .presentation {  
    background-image: url(/images/banner_evaluate_responsive.png) !important;
    background-size: auto 90%;
    /* height: auto; */
  }
}
