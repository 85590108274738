#search .verse-id .link-verse {
  font-style: italic;
  color: #008db7 !important;
  font-size: .85em;
}

#search .verse-id .link-verse:hover {
  text-decoration: underline !important;
}

#search .verse-about-box {
  font-size: .9em;
  color: #999;
  margin: 4px 16px;
  border: solid 1px #eee;
  padding: 8px;
  background: #f3f3f3;
}

#search .verse-about-box .verse-about-result .search-id {
  font-weight: bold;
}

#search .verse-about-box .verse-about-result.w2v .search-id {
  color: #666699;
}

#search .verse-about-box .verse-about-result.direct .search-id {
  color: #000;
}

#search .verse-about-box .verse-about-result.concordance .search-id {
  color: #e73b94;
}

#search .featured-term-search {
 font-weight: 700;
 color: #222; 
 /* text-decoration: underline; */
}