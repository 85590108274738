.home-presentation {
    height: calc(100% - 118px);
}

#search-home {
    padding-top: 24px;
    padding-bottom: 100px;
    text-align: center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 100%;
}

#search-home-bg {
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 100%;
}

#search-home .tool-bar .link-page.link-featured {
  border: solid 1px rgba(255, 255, 255, .4);
  padding: 15px 16px;
}

#search-home .tool-bar {
  box-shadow: none;
}

#search-home .tool-bar * {
  /* color: #fff !important; */
}

#search-home .tool-bar .link-page {
  text-decoration: none;
  margin: 0;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;

  background: transparent;
  -webkit-transition-property: background;
  -webkit-transition-duration: 1s;
  transition-property: background;
  transition-duration: 1s;
}

#search-home .bible-home {
    display: none;
    margin: 0px auto;
    margin-top: 24px;
    max-width: 360px;
    opacity: .05;
}

#search-home.style-0, #search-home.style-3 {
	background-image: url(/images/grafismo.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}
#search-home.style-1 {
	background-image: url(/images/mulher-lendo.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 25%;
}
#search-home.style-2 {
	background-image: url(/images/homem-lendo.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 32%;
}

#content-home.style-2 * {
    color: #fff !important;
}

#content-home.style-2 .be-title-page {
    border-bottom: solid 1px #fff;
}

#search-home .box-all {
	display: block;
    position: relative;
    top: 20px;
    /*height: 210px;*/
    /*margin-top: -140px;*/
}

#search-home .area-serch-icon {
    display: inline;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}

#search-home .slogan-project,
#search-home .loading-text,
#search-home .input-react-fetch-auto-complete *,
#search-home .search *
{
	color: #4e4f51 !important;
	font-weight: normal;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4e4f51 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4e4f51 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4e4f51 !important;
}

#search-home .slogan-project {
	margin: 40px auto;
  font-size: 1.5em;
}

#search-home .logo {
	max-width: 240px;
	display: block;
	margin: 0px auto;
	margin-bottom: 40px;
}

#search-home .search {
  border: solid 1px #ccc;
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 0px;
  width: 60%;
  max-width: 540px;
  border-bottom: solid 2px #ccc;
  background: #fff;
}

#search-home .input-react-fetch-auto-complete {
	border: none !important;
  outline: none !important;
  width: 72%;
  background-color: transparent;
  margin-left: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 24px;
  font-size: 16px;
}

#search-home .loading-text {
	font-style: italic;
}

#search-home .box-suggestions {
	width: 40%;
    max-width: 500px;
	  margin: 20px auto;
    display: block;
    box-shadow: none;
    padding: 24px;
    text-align: left;
    position: relative;
    display: block;
    background: #fff;
}

#search-home .box-suggestions .result-suggestion {
    cursor: pointer;
}

#search-home .box-suggestions .result-suggestion .tag-result-name {
	display: table;
}

#search-home .box-suggestions .result-suggestion .tag-result-name * {
	display: table-cell;
    vertical-align: middle;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.person * {
    color: #008db7;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.place * {
    color: #6dbb9c;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.book * {
	color: #e73b94;
}

#search-home .box-suggestions .result-suggestion .tag-result-name.concept * {
	color: #666699;
}

#search-home .box-suggestions .result-suggestion .tag-result-name svg {
	margin-right: 10px;
}

#search-home .box-suggestions .result-suggestion .tag-result-name .search-result-name {
	text-transform: uppercase;
}

#content-home {    
    padding: 80px;
}

#content-home .content-area {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}

#content-home .content-image {
    width: 40%;
    height: auto;
    margin: 0;
}

#content-home .content-image img {
    display: block;
    width: 80%;
}

#content-home .content {
    width: 59%;
    margin: 0;
}

#search-home .button-evaluate {
  background: #008db7;
  padding: 24px;
  font-weight: bold;
  border-radius: 0;
  margin-top: 64px;
}

#search-home .button-evaluate * {
  color: #fff !important;
}

@media (max-width: 1362px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search-home .tool-bar .link-page.link-featured {
    padding: 16px 4px;
  }
  #search-home .tool-bar .link-page.link-featured span{
    font-size: 10px;
  }
}

#search-home .c2 {}

#search-home .c3 {
  display: none;
}

#search-home .c4 {
  display: none;
}

#search-home .be-divider {
  height: 1px;
  width: 40%;
  margin: 0 auto;
  padding: 0;
  background: #ddd;
  margin-top: 24px;
}

/* #search-home .area-indexes-book {
  background: #008db7;
} */


.insta-info {
  padding: 64px 0;
  text-align: center;
  border-top: solid 2px #576767;
}

.insta-info .be-title-footer,
.insta-info .be-title-footer * {
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: bold;
}

.insta-info .be-title-footer a {
  color: #008db7 !important;
}

.insta-info .new-section {
  margin-top: 64px;
}

@media (max-width: 1820px) {
  #search-home .be-divider {
    width: 100%;
  }
}

@media (max-height: 960px) {
  #search-home .c2 {
    display: none;
  }
  #search-home .c3 {
    display: block;
  }
}

@media (max-height: 830px) {
  #search-home .c2,
  #search-home .c3 {
    display: none;
  }
  #search-home .c4 {
    display: block;
  }

  #search-home {
    background-image: none !important;
  }
}

@media (max-height: 750px) {
  #search-home .be-divider,
  #search-home .activite.read,
  #search-home .c2,
  #search-home .c3,
  #search-home .c4 {
    display: none;
  }
}

@media (max-width: 1660px) and (min-height: 980px) {
  #search-home .c2,
  #search-home .c4 {
    display: none;
  }
  #search-home .c3 {
    display: block;
  }
}

@media (max-width: 1110px) {
  #search-home .be-divider,
  #search-home .activite.read,
  #search-home .c2,
  #search-home .c3,
  #search-home .c4 {
    display: none;
  }
}

@media (max-width: 1200px)  {
    #search-home {
        background-image: none !important;
    }
    #search-home .bible-home {
        display: block;
    }
}

@media (max-width: 920px)  {
    #content-home {
        background-image: none !important;
    }
	#content-home .content-area {
        display: block;
    }
    #content-home .content-image {
        width: 70%;
        display: block;
        margin: 0;
    }
	#search-home .search {
		width: 100%;
    }
    
    #content-home .content {
        width: 90%;
    }
}

@media (max-width: 800px)  {
  .insta-info .box-twitter {
    display: none;
    text-align: center;
  }
  
	#search-home .input-react-fetch-auto-complete {
		/* width: 100%; */
    }

	#search-home .box-suggestions {
		width: 80%;
		box-sizing: border-box;
	}
}

@media (max-width: 600px)  {
	#search-home {
		padding: 100px 20px;
        box-sizing: border-box;
        overflow: hidden;
    }
    #search-home .box-all {
        top: 0;
    }
    #search-home .box-all .logo {
        max-width: 90%;
    }
    #search-home .box-all .slogan-project {
        display: none;
    }
    .home-presentation {
        height: calc(100% - 174px) !important;
    }
}

