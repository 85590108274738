#search .area-results h4 {
  margin-top: 64px;
}

#search .area-results h4.w2v .resource {
  color: #666699;
  font-weight: bold
}

#search .area-results h4.concordance .resource {
  color: #e73b94;
  font-weight: bold
}

#search .area-results h4.direct .resource {
  color: #008db7;
  font-weight: bold
}

#search .area-results .box-verse.concordance {
  border-left: solid 3px #e73b94;
}

#search .area-results .box-verse.w2v {
  border-left: solid 3px #666699;
}

#search .area-results .box-verse.direct {
  border-left: solid 3px  #008db7 
}