.card-dashboard {
  border: solid 1px #eee;
  margin: 24px;
  padding: 16px;
  text-align: center;
}
.card-dashboard p.featured-number {
  font-size: 24px;
  font-weight: bold;
}

.card-dashboard p.label-number {
  text-transform: uppercase;
  font-size: 14px;
}

.card-dashboard:nth-child(1) .featured-number {
  color: #008db7;
}

.card-dashboard:nth-child(1) {
  border-left: solid 1px #008db7;
  border-bottom: solid 6px #008db7;
}

.card-dashboard:nth-child(2) .featured-number {
  color: #e73b94;
}

.card-dashboard:nth-child(2) {
  border-left: solid 1px #e73b94;
  border-bottom: solid 6px #e73b94;
}

.card-dashboard:nth-child(3) .featured-number {
  color: #ffcc66;
}

.card-dashboard:nth-child(3) {
  border-left: solid 1px #ffcc66;
  border-bottom: solid 6px #ffcc66;
}

.card-dashboard:nth-child(4) .featured-number {
  color: #6dbb9c;
}

.card-dashboard:nth-child(4) {
  border-left: solid 1px #6dbb9c;
  border-bottom: solid 6px #6dbb9c;
}

.card-dashboard:nth-child(5) .featured-number {
  color: #666699;
}

.card-dashboard:nth-child(5) {
  border-left: solid 1px #666699;
  border-bottom: solid 6px #666699;
}

.card-dashboard:nth-child(6) .featured-number {
  color: #cc3333;
}

.card-dashboard:nth-child(6) {
  border-left: solid 1px #cc3333;
  border-bottom: solid 6px #cc3333;
}

