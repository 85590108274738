#search-tool-bar {
  display: block;
}

#search .tool-bar {
  box-shadow: none;
}

#search .tool-bar .link-page.link-featured {
  display: inline;
}

#search .tool-bar .link-page.link-featured .icon-tab-item {
  display: inline-block;
  position: relative;
  top: 8px;
  margin-right: 4px;
}

#search .tool-bar .link-page.link-featured.all.selected {
  border-bottom: solid 2px #333
}

#search .tool-bar .link-page.link-featured .icon-tab-item.direct-tab * {
  color: #008db7;
}

#search .tool-bar .link-page.link-featured.direct.selected {
  border-bottom: solid 2px #008db7
}

#search .tool-bar .link-page.link-featured .icon-tab-item.concordance-tab * {
  color: #e73b94;
}

#search .tool-bar .link-page.link-featured.concordance.selected {
  border-bottom: solid 2px #e73b94
}

#search .tool-bar .link-page.link-featured .icon-tab-item.terms-tab * {
  color: #666699;
}

#search .tool-bar .link-page.link-featured.w2v.selected {
  border-bottom: solid 2px #666699
}

#search .tool-bar .link-page.link-featured .icon-tab-item.help-tab * {
  color: #6dbb9c;
}

#search .tool-bar .link-page.link-featured .icon-tab-item.model-tab * {
  color: #cc3333;
}

#search .tool-bar .link-page.link-featured.explicability-search.selected {
  border-bottom: solid 2px #6dbb9c
}

#search .tool-bar .link-page.link-featured.explicability-model.selected {
  border-bottom: solid 2px #cc3333
}

#search .tool-bar {
  background: #fefefe;
  border-bottom: solid #ccc 1px;
  box-shadow: none;
  padding-top: 20px;
  box-sizing: border-box;
  height: 64px;
}

#search .tool-bar.green-tool-bar {
  background: #6dbb9c;
  box-shadow: none;
}

#search .tool-bar * {
  /* color: #fff !important; */
}

#search .tool-bar .link-page {
  text-decoration: none;
  margin: 0;
  text-transform: uppercase;
  font-size: .8em;
  cursor: pointer;

  background: transparent;
  -webkit-transition-property: background;
  -webkit-transition-duration: 1s;
  transition-property: background;
  transition-duration: 1s;
}

#search .tool-bar .link-page:hover {
  background: #f4f4f4;
}

#search .tool-bar .link-page.link-featured {
  border: solid 1px rgba(255, 255, 255, .4);
  padding: 15px 16px;
}


@media (max-width: 1718px)  {
  #search .tool-bar .content{
    max-width: 95% !important;
  }
}

@media (max-width: 1460px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search .tool-bar .link-page.link-featured {
    padding: 16px 8px;
  }
}

@media (max-width: 1362px)  {
  #search .tool-bar .content{
    max-width: 100% !important;
  }
  #search .tool-bar .link-page.link-featured {
    padding: 16px 4px;
  }
  #search .tool-bar .link-page.link-featured span {
    font-size: 10px;
  }
}

@media (max-width: 850px) {
  #search #search-tool-bar .tool-bar .link-page.link-featured {
    display: block !important;
    padding: 8px;
    background: #fafafa;
    position: relative;
    top: 10px;
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured .icon-tab-item {
    top: 1px;
    margin-right: 8px;
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured span {
    font-size: 12px;
    position: relative;
    top: -8px;
  }

  #search #search-tool-bar .tool-bar {
    height: 248px;
    border-top: solid 1px #ccc;
    padding: 0;
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured.all.selected {
    border: solid 2px #333
  }

  #search #search-tool-bar .tool-bar .link-page.link-featured.direct.selected {
    border: solid 2px #008db7
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.concordance.selected {
    border: solid 2px #e73b94
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.w2v.selected {
    border: solid 2px #666699
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.explicability-search.selected {
    border: solid 2px #6dbb9c
  }
  
  #search #search-tool-bar .tool-bar .link-page.link-featured.explicability-model.selected {
    border: solid 2px #cc3333
  }
}

@media (max-width: 800px)  {
  #search .tool-bar .no-responsive {
     display: none;
  }
}