.social-networks{
	text-align: center;
	display: block;
	flex: 1;
	width: 100%;
	background: #008db7;
	margin: 0 !important;
}
.area-footer, .box-icons{
	margin: 0 !important;
	padding: 0 !important;
}
.area-footer .box-icons, .box-icons,
.footer-home .box-icons {
	height: 80px;
}
.area-footer .icon, .box-icons .icon,
.footer-home .icon {
	background-image: url(/images/icons_social_networks_white.png);
	background-color: transparent;
	background-size: 300px 100px;
	background-position: 300px 0;
	height: 50px;
	width: 50px;
	cursor: pointer;
	display: inline-block;
	margin: 0px;
	margin-top: 10px;
	-webkit-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-ms-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 25px;
}
.area-footer .icon:hover, .box-icons .icon:hover,
.footer-home .icon:hover {
	margin-bottom: 10px;
	margin-top: 0;
	background-image: url(/images/icons_social_networks_white.png);
}
.area-footer #icon-01,
.box-icons #icon-01 {
	background-position: 0 0;
}
.area-footer #icon-02,
.box-icons #icon-02 {
	background-position: -50px 0;
}
.area-footer #icon-03,
.box-icons #icon-03 {
	background-position: -100px 0;
}
.area-footer #icon-04,
.box-icons #icon-04 {
	background-position: -150px 0;
}
.area-footer #icon-05,
.box-icons #icon-05 {
	background-position: -200px 0;
}
.area-footer #icon-06,
.box-icons #icon-06 {
	background-position: 0 50px;
}
.area-footer #icon-07,
.box-icons #icon-07 {
	background-position: -50px 50px;
}
.area-footer #icon-08,
.box-icons #icon-08 {
	background-position: -100px 50px;
}
.area-footer #icon-09,
.box-icons #icon-09 {
	background-position: -150px 50px;
}
.area-footer #icon-10,
.box-icons #icon-10 {
	background-position: -200px 50px;
}
.area-footer #icon-11,
.box-icons #icon-11 {
	background-position: -250px 0px;
}

@media only screen and (max-width: 970px){
	.column-footer{
		display: block;
		width: 100% !important;
		max-width: 100% !important;
		text-align: center;
		margin-top: 10px;
		padding-bottom: 10px;
		border-bottom: solid 1px #deeeee;
	}
}
