#form-evaluation {

}

#form-evaluation #info-demo .form-control {
  border: solid 1px #ddd;
    padding: 24px;
    margin: 16px;
}

#form-evaluation .box-step-evaluation {
  margin: 24px auto;
}

