#table-component {
    display: block;
}

#table-component .table {
    border-collapse: collapse;
}

#table-component .table tr td {
    border-bottom: 1px solid #eee;
    padding: 12px 16px;
}

#table-component .table tr td.cos-sims {
    /* border-left: 1px solid #ddd; */
    text-align: right;
}

#table-component .table {
    border: #fff;
}

#table-component .table tr.th td {
    padding: 24px;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif !important;
    border-bottom: 2px solid #4e4f51;
}
