.area-pie-chart {
  display: flex;
  align-items: center;
  margin: 24px auto;
  border-bottom: solid 1px #eee;
  max-width: 520px;
}

.area-pie-chart .color-item-pie {
  height: 12px;
  width: 12px;
  background-color: #000;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 8px;
}


.area-pie-chart .item-pie-1 .color-item-pie {
  background-color: #6dbb9c;
}

.area-pie-chart .item-pie-2 .color-item-pie {
  background-color: #ffcc66;
}

.area-pie-chart .item-pie-3 .color-item-pie {
  background-color: #008db7;
}

.area-pie-chart .item-pie-4 .color-item-pie {
  background-color: #666699;
}

.area-pie-chart .item-pie-5 .color-item-pie {
  background-color: #e73b94;
}

.area-pie-chart .item-pie-6 .color-item-pie {
  background-color: #cc3333;
}