#area-charts-logs {
  display: block;
}

#area-charts-logs h2 {
  text-align: center;
}

#area-charts-logs .area-box-charts {
  display: flex;
}