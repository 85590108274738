#search.evaluate {
  padding-bottom: 400px;
}

#search.evaluate #area-evaluate-questions {
  position: fixed;
  bottom: 0;
  width: 100%;
  border: solid 1px #eee;
  background: #333;
  -webkit-box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.32);
  -moz-box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.32);
  box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.32);
}

#search.evaluate #area-evaluate-questions.results {
  background: #008db7;
}

#search.evaluate #area-evaluate-questions.explanation {
  background: #58a687;
}

#search.evaluate #area-evaluate-questions.trust {
  background: #666699;
}

#search.evaluate #area-evaluate-questions.next {
  background: #737a84;
}

#search.evaluate #area-evaluate-questions.comment {
  background: #e73b94;
}

#search.evaluate #area-evaluate-questions.done {
  background: #58a687;
}

#search.evaluate #area-evaluate-questions * {
  color: #fff !important;
  border-color: #fff;
}

#search.evaluate #area-evaluate-questions .form-evaluate > label {
  display: inline-block;
}

#search.evaluate #area-evaluate-questions .form-evaluate > .field {
  display: inline-block;
}

#search.evaluate #area-evaluate-questions .form-evaluate > .field .item{
  margin-right: 64px;
}

#search.evaluate #area-evaluate-questions textarea.comment {
  outline: none;
  display: block;
  width: 100%;
  min-height: 80px;
  resize: none;
  margin: 20px auto !important;
  color: #111 !important;
  font-size: 14px;
}

#search.evaluate #area-evaluate-questions .comment-evaluate .button-evaluation {
  padding: 16px;
  background: rgba(0, 0, 0, .5);
  border-radius: 0;
  margin-top: 12px;
  align-items: flex-end;
}

#search.evaluate #area-evaluate-questions .comment-evaluate .button-evaluation * {
  color: #fff !important;
}

#search.evaluate #area-evaluate-questions .area-button-comment {
  display: grid;
}


#search.evaluate #area-evaluate-questions .finished-evaluate {
  display: block;
  margin: 0 auto;
  text-align: center;
}

#search.evaluate #area-evaluate-questions .finished-evaluate .button-evaluation {
  padding: 24px;
  background: #fff;
  border-radius: 0;
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;
}

#search.evaluate #area-evaluate-questions .finished-evaluate .button-evaluation * {
  color: #333 !important;
}

#search.evaluate #footer {
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 54px;
  background: rgba(255, 255, 255, .9); 
  /*background: #1C2A39;*/
  padding: 4px 0;
  box-sizing: border-box;
  border-top: solid 1px #ccc;
  margin-top: 16px;
}

#search.evaluate #footer * {
  color: #e73b94 !important
}

#search.evaluate #area-evaluate-questions .be-title-page {
  margin: 0;
  padding-top: 0;
  padding-bottom: 8px;
}

#search.evaluate #area-evaluate-questions .be-title-page.final-title {
  margin: 32px auto;
}

@media (max-width: 850px) {
  #search.evaluate #search-tool-bar .tool-bar {
    height: 120px !important;
  }

  #search.evaluate #evaluate-header header > div {
    padding-top: 48px;
    width: auto;
    margin: auto;
  }
}

@media (max-width: 648px) {
  #search.evaluate #area-evaluate-questions .be-title-page {
    width: 100%;
    font-size: 20px;
  }
}

